<ion-card class="mm--alert-container">
  <ion-card-header lines="none">
    <ion-icon *ngIf="showCloseButton" (click)="close()" name="close-circle-outline" class="close-button" size="large"></ion-icon>
    <div class="title-container">
      <ion-icon *ngIf="showAlertIcon" name="warning-outline" ></ion-icon>
      <ion-card-title> {{ header }} </ion-card-title>
    </div>
  </ion-card-header>
  <ion-card-content>
    <div *ngIf="message || projectedContent" class="card-text">
      <ion-text *ngIf="message"> {{ message }} </ion-text>
      <ng-container *ngIf="projectedContent" [ngTemplateOutlet]="projectedContent"></ng-container>
    </div>
    <ion-button *ngFor="let button of buttons"
      class="submit-button" 
      expand="block"
      (click)="handler(button.handler)">
        {{ button.text }}
    </ion-button>
  </ion-card-content>
</ion-card>