
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, from,  } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from '../services/toast.service';





export function authGuardCanActivateChild(): CanActivateChildFn {
  // return (route: ActivatedRouteSnapshot,state: RouterStateSnapshot) => authGuardCanActivate()(route, state)
  return authGuardCanActivate();
}

export function authGuardCanActivate(): CanActivateFn {
  return (route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
    const router = inject(Router);
    const authService = inject(AuthService);
    const toastService = inject(ToastService);
  
    return from(authService.isAuthenticated()).pipe(  //TODO:  revert to this hopefully
      tap( (isLoggedIn: boolean) => console.log('AuthGuard isLoggedIn:', isLoggedIn)),
      tap( (isLoggedIn: boolean) => isLoggedIn ?? toastService.show('You must be logged in to perform this action.', {
            color: 'danger',
          })
      ),
      tap( (isLoggedIn: boolean) => console.log('AuthGuard isLoggedIn:', isLoggedIn)),
      map( (isLoggedIn: boolean) => isLoggedIn ? true : router.createUrlTree(['/']) )
    );
  }
}