import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Memoize } from 'typescript-memoize';
import {  map, Observable, shareReplay, tap } from 'rxjs';
// import * as surveySchema from './questionnaireMockSurveySchema.json';
import { ClientCaseCard } from './case.service';
import { environment } from 'src/environments/environment';



export interface QuestionnaireResponse {
  id: number|string;
  content: Questionnaire;
  name: string
}

export interface Questionnaire {
  title: string;
  logoPosition: string;
  pages: QuestionnairePage[]
}

export interface QuestionnairePage {
  name: string;
  title: string;
  elements: QuestionnairePageElement[]
}

export interface QuestionnairePageElement {
  type: string;
  name: string;
  title: string;
  description: string;
}

export interface SurveyAnswersItem{
  id: number|string;
  survey_template_id: number;
  user_id: number;
  content: any;
  salesforce_intake_id: string;
  salesforce_matter_id: string;
  completed: boolean;
  created_at: string;
  updated_at: string;
}

export interface WriteSurveyAnswersItem {
  message: string;
  id: number|string;
  user_id: number;
  salesforce_intake_id: string;
  salesforce_matter_id: string;
  content: any;
  completed: boolean;
}





@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  constructor(
    private http: HttpClient,
  ) { }



  @Memoize()
  public getQuestionnaire(surveyName: string): Observable<QuestionnaireResponse> {
    const query = `?name=${surveyName}`
    return this.http.get<QuestionnaireResponse[]>(`${environment.api.edwinBaseUrl}/survey_templates/${query}`).pipe(
      tap(questionnaireResponse => {
        if(questionnaireResponse.length === 0){
          throw new Error(`Not a valid questionnaire: "${surveyName}"`);
        }
      }),
      map(questionnaire => questionnaire.length ? questionnaire[0] : null),
      tap( () => console.info("QuestionnaireService.getQuestionnaire() [MEMOIZED and called by CaseService] --> surveyName:", surveyName)),
      shareReplay(1),
    );
    // return of(<QuestionnaireResponse>{id: 1, content: surveySchema, name: 'AA_Onboarding_Survey'}).pipe(delay(500), take(1));
  }

  public getSurveyAnswers(surveyId:number|string, intakeId:string, matterId?:string ): Observable<SurveyAnswersItem | null>{
    const query = `?salesforce_intake_id=${intakeId}&survey_template_id=${surveyId}`
    return this.http.get<SurveyAnswersItem[]>(`${environment.api.edwinBaseUrl}/survey_responses/${query}`).pipe(
      map(surveyAnswers => surveyAnswers.length > 0 ? surveyAnswers[0] : null),
    );
  }

  public createSurveyResponse(survey_id: number|string, intakeId:string, matterId?:string): Observable<WriteSurveyAnswersItem> {
    const data = {
      survey_template_id: survey_id,
      content: {},
      salesforce_intake_id: intakeId,
      salesforce_matter_id: matterId,
    }

    return this.http.post<WriteSurveyAnswersItem>(`${environment.api.edwinBaseUrl}/survey_responses/`, data)
  }

  public createCompletedSurveyResponse(survey_id: number|string, intakeId:string, matterId?:string, content = {}): Observable<WriteSurveyAnswersItem> {
    const data = {
      survey_template_id: survey_id,
      content,
      salesforce_intake_id: intakeId,
      salesforce_matter_id: matterId,
      completed: true
    }

    return this.http.post<WriteSurveyAnswersItem>(`${environment.api.edwinBaseUrl}/survey_responses/`, data)
  }

  public updateSurveyAnswers(id: number|string, survey_id: number|string, content: any, intakeId:string, matterId?:string): Observable<WriteSurveyAnswersItem> {
    const data = {
      survey_template_id: survey_id,
      content,
      salesforce_intake_id: intakeId,
      salesforce_matter_id: matterId,
    }

    return this.http.put<WriteSurveyAnswersItem>(`${environment.api.edwinBaseUrl}/survey_responses/${id}`, data).pipe(
      tap( (surveyAnswers) => console.warn('QuestionnaireService.UPDATEsurveyAnswers() surveyAnswers:', surveyAnswers))
    );
  }

  public completeSurveyResponse(id: number|string, survey_id: number|string, content: any, intakeId: string, matterId?:string,): Observable<WriteSurveyAnswersItem> {
    
    const data = {
      survey_template_id: survey_id,
      content,
      salesforce_intake_id: intakeId,
      salesforce_matter_id: matterId,
      completed: true
    } 

    return this.http.put<WriteSurveyAnswersItem>(`${environment.api.edwinBaseUrl}/survey_responses/${id}`, data);
  }

  public hasCompletedSurvey(surveyId: number|string, intakeId:string, referenceNumber=''): Observable<boolean> {
    return this.getSurveyAnswers(surveyId, intakeId).pipe(
      // tap(surveyResponse => console.log("QuestionnaireService.hasCompletedSurvey() surveyResponse.completed:", referenceNumber, surveyResponse)),
      map(surveyResponse => surveyResponse === null ? false : surveyResponse.completed)
    )
  }
}
