import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';
const colors = 'color:purple; background: silver';


@Injectable({
  providedIn: 'root',
})
export class MixPanelService {
  private path: string;
  logging: boolean = environment.consoleLogs.mixpanel;

  constructor() {}

  public async init() {
    const initialize: boolean = !location.host.includes('localhost');
    this.logging && console.log('%c MixPanel.init() :', colors, initialize ? 'Initializzing' : 'NOT Initializing for localhost' );
    if(initialize) {
      await mixpanel.init(environment.mixPanel.apikey, { debug: true });
    }
    // mixpanel.identify(userToken);
  }

  public async track(id: string, action: any = {}) {
    this.logging && console.log('%c MixPanel.track(id, action): ', colors, id, action);
    if(mixpanel.__loaded) await mixpanel.track(id, action, { send_immediately: true });
  }

  public async setSuperProperty(action: any = {}) {
    this.logging && console.log('%c MixPanel.setSuperProperty(action): ', colors, action);
    if(mixpanel.__loaded)  mixpanel.register(action);
  }
  public async setPeople(id: string, people: any = {}) {
    this.logging && console.log('%c MixPanel.setPeople(id, people): ', colors, id, people);
    if(mixpanel.__loaded) {
      await mixpanel.identify(id);
      await mixpanel.people.set(people);
    }
  }

  public async setAlias(alias: string, id: string) {
    this.logging && console.log('%c MixPanel.setAlias(alias, id): ', colors, alias, id);
    if(mixpanel.__loaded)  await mixpanel.alias(alias, id);
  }

  public setPath(path: string): void {
    this.logging && console.log('%c MixPanel.setPath(path): ', colors, path);
    this.path = path;
  }

  public async trackWithPath(id: string, action: any = {}) {
    this.logging && console.log('%c MixPanel.trackWithPath(id, action): ', colors, id, action);
    action.path = this.path;
    if(mixpanel.__loaded)  await mixpanel?.track(id, action);
  }

  public async trackForm(id: string, action: any = {}) {
    this.logging && console.log('%c MixPanel.trackForm(id, action): ', colors, id, action);
    if(mixpanel.__loaded)  await mixpanel.track_forms(id, action);
  }
}
