import { Component, OnInit, Input } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons';

import { environment } from 'src/environments/environment';
import { IonicModule } from '@ionic/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
const { phone } = environment;

@Component({
  standalone: true,
  selector: 'mm-scheduled-maintenance',
  templateUrl: './scheduled-maintenance.component.html',
  styleUrls: ['./scheduled-maintenance.component.scss'],
  imports: [IonicModule, FontAwesomeModule],
})
export class ScheduledMaintenanceComponent implements OnInit {
  @Input() startTime: string = '';
  @Input() endTime: string = '';

  protected readonly faScrewdriverWrench = faScrewdriverWrench;
  protected readonly phone = phone;

  constructor(private navParamsService : NavParams) { }

  ngOnInit(): void {
    this.startTime = this.navParamsService.get('startTime');
    this.endTime = this.navParamsService.get('endTime');
  }
}
