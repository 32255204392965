import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UpgradeRequired, UpgradeService } from 'src/app/services/upgrade.service';
import { faSparkles, faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.page.html',
  styleUrls: ['./upgrade.page.scss'],
})
export class UpgradePage {

  protected readonly faSparkles = faSparkles;
  protected readonly faScrewdriverWrench = faScrewdriverWrench;
  
  public upgradeRequired$: Observable<UpgradeRequired> = this.upgradeService.upgradeRequired$;

  constructor(
    private upgradeService: UpgradeService,
  ) { }

}
