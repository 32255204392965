import { Observable, Subject, defer, timer } from "rxjs";
import { finalize, switchMap, map } from 'rxjs/operators';
export function prepare<T>(callback: () => void): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>): Observable<T> => defer(() => {
      callback();
      return source;
    });
  }
  
  export function indicate<T>(indicator: Subject<boolean>): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>): Observable<T> => source.pipe(
      prepare(() => indicator.next(true)),
      finalize(() => indicator.next(false))
    )
  }

  export function delay<T>(delay: number): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) => source.pipe(
      switchMap(value => timer(delay).pipe(map(() => value)))
    );
  }