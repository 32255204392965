<div class="contact-how ion-page">
  <ion-header mode="ios">
    <ion-toolbar mode="ios" class="header-toolbar">
      <ion-title>Meeting Info</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="closeMeetingModal()" routerLink="/tabs/case" routerDirection="back">
          <mm-close-button-svg></mm-close-button-svg>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content fullscreen="true">
    <div class="art-row">
      <ng-lottie width="327px" height="290px" [options]="options"></ng-lottie>
    </div>
    <ion-grid>
      <ion-row class="header-row">
        <ion-col size="12">
          <ion-text class="header-text-1">{{ call.case_team_name }}</ion-text>
          <ion-text class="header-text-1">
            {{ call.rescheduled_date ? formatMeetingDateTime(call.rescheduled_date) : formatMeetingDateTime(call.appointment_date) }}
          </ion-text>
          <ion-text class="header-text-2">{{ call.user_notes }}</ion-text>
          <ion-text class="header-text-3">{{ call.topic }}</ion-text>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</div>