import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BrowserVault, DeviceSecurityType, Vault, VaultType} from '@ionic-enterprise/identity-vault';
import { AuthResult } from '@ionic-enterprise/auth';
import { StorageService } from './storage.service';


const config = {
  key: 'io.ionic.gettingstartedacangular',
  type: VaultType.SecureStorage,
  deviceSecurityType: DeviceSecurityType.None,
  lockAfterBackgrounded: 5000,
  shouldClearVaultAfterTooManyFailedAttempts: true,
  customPasscodeInvalidUnlockAttempts: 2,
  unlockVaultOnLoad: false,
}

const vaultKey = 'auth-result';
const vaultNewKey = 'auth-result-new';

@Injectable({
  providedIn: 'root'
})
export class VaultService implements SessionStorageInterface {

  private vault: Vault | StorageService;

  constructor(
    private platform: Platform,
    private storageService: StorageService,
  ) {
    this.vault = this.platform.is('hybrid') ? new Vault(config) : this.storageService;
  }

  public clear(): Promise<void> {
    return this.vault.clear();
  }

  public getSession(): Promise<AuthResult | null> {
    return this.vault.getValue<AuthResult>(vaultKey);
  }

  public setSession(value: AuthResult): Promise<void> {
    return this.vault.setValue(vaultKey, value);
  }

  public setNewSession(value: AuthResult): Promise<void> {
    return this.vault.setValue(vaultNewKey, value);
  }
}




export interface SessionStorageInterface {
  clear(): Promise<void>;
  getSession(): Promise<AuthResult | null>;
  setSession(value: AuthResult): Promise<void>;
  setNewSession(value: AuthResult): Promise<void>;
}