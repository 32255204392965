import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap, combineLatest, map, BehaviorSubject, Observable, tap, shareReplay, filter, merge, iif, of, firstValueFrom, skip, distinctUntilChanged, catchError, startWith, defaultIfEmpty } from 'rxjs';
import { StorageService } from './storage.service';
import { AppSchema, AppSchemaService } from '../directives/app-schema/app-schema.service';
import { AuthService, AuthState } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CaseService {
  public casesLoading$ = new BehaviorSubject<boolean>(false);
  private casesRefresh$ = new BehaviorSubject<null>(null); //not needed with matter and intake forceRefresh
  private setCaseId$ = new BehaviorSubject<string>('');
  private setMatterId$ = new BehaviorSubject<string>('');

  public cases$: Observable<ClientCase[]> = this.authService.currentState$
    .pipe(
      filter((authState: AuthState) => !!authState?.jwt),
      switchMap(() => 
        this.casesRefresh$.pipe(
          switchMap(() => this.http.get<CaseResponse[]>(`${environment.api.edwinBaseUrl}/cases/`)),
          catchError((err) => {
            console.error('Error fetching cases from Edwin', err);
            return of([]);
          })
        )
      ),
      map((casesFromEdwin: CaseResponse[]): ClientCase[] => {
        return casesFromEdwin.map(transformCaseResponse);
      }),
      switchMap((cases: ClientCase[]) =>
        combineLatest(
          cases.map((clientCase: ClientCase) => this.appSchemaService.getTemplate(clientCase).pipe(
            map((schema: AppSchema) => ({
              ...clientCase,
              appSchema: schema,
            })),
            catchError((err) => of(clientCase)),
          ))
        )
      ),
      // TODO: sorting may not be needed if Edwin does it. may need to add sorting param to /api/cases
      map((cases: ClientCase[]) => cases.sort((a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate))),
      map((cases: ClientCase[]) => cases.sort((a, b) => (b.caseStatus === 'active' || b.caseStatus === 'pending') ? 1 : -1)),
      tap((cases: ClientCase[]) => console.log('cases alt:::', cases)),
      startWith([] as ClientCase[]),
      shareReplay(1),
    );
  public matters$: Observable<CaseMatter[]> = this.cases$.pipe(
    map((cases: ClientCase[]) => cases
      .filter((clientCase: ClientCase) => !!clientCase.matter)
      .map((clientCase: ClientCase) => clientCase.matter)
    )
  );

  public selectedCaseInit$: Observable<ClientCase> = this.cases$.pipe(
    distinctUntilChanged( (prev,curr) => JSON.stringify(prev)===JSON.stringify(curr)),
    switchMap((cases: ClientCase[]) =>
      merge(
        this.setCaseId$.pipe( //Most Likely tapped in ClientCaseSelection
          filter((id:string) => id !== ''),
          map((intakeId: string) => cases.find((clientCase: ClientCase) => clientCase.intakeId === intakeId)),
        ),
        this.setMatterId$.pipe( //Most Likely tapped in NotificationCenter
          filter((id:string) => id !== ''),
          map((matterId:string) => cases.filter((clientCase: ClientCase)=> clientCase.matter).find((clientCase: ClientCase) => clientCase.matter.id === matterId ) ),
        )
      )
    ),
    switchMap((clientCase: ClientCase) => iif(
      () => clientCase === undefined,
      of(null).pipe(skip(1)),
      of(clientCase).pipe(
        tap((clientCase: ClientCase) => this.storageService.set('selectedCaseId', clientCase.intakeId)),
        filter((clientCase: ClientCase) => clientCase !== undefined )
      ).pipe(
      )
    )),
    startWith(<ClientCase>{}), //Add this so that even on first subscribe, the NEXT case is always take(2) away!
    tap( (cc) => console.warn('CaseService.selectedCase$ intakeID:', cc.intakeId, '- matterID:', cc.matter?.id) ),
    shareReplay(1),
  );

  public selectedCase$: Observable<ClientCase> = this.selectedCaseInit$.pipe(
    filter((clientCase: ClientCase) => JSON.stringify(clientCase) !== JSON.stringify({})),
  );

  public legalTeam$: Observable<LegalTeamMember[]> = this.selectedCase$.pipe(
    map((clientCase: ClientCase) => clientCase.matter?.legalTeam),
  );

  public legalTeamPreferredContact$ = this.legalTeam$.pipe(
    map(
      (legalTeam: LegalTeamMember[]) => {
        const cm = legalTeam?.find(tm => tm.role==='Case Manager');
          if(cm) {
            return cm;
          }
          const pl = legalTeam?.find(tm => tm.role==='Paralegal');
          if(pl) {
            return pl;
          }
          throw Error('no preferred contact');
      }
    ),
  );

  public selectedCaseAppSchemaTemplate$: Observable<AppSchema> =
    this.selectedCase$.pipe(
      switchMap((clientCase: ClientCase) => this.appSchemaService.getTemplate(clientCase))
    );

  constructor(
    private storageService: StorageService,
    private appSchemaService: AppSchemaService,
    private authService: AuthService,
    private http: HttpClient,
  ) {
    this.init();
  }

  async init() {
    const caseId = await this.storageService.get('selectedCaseId');
    console.warn('CaseService.init() caseId:', caseId);
    if(caseId) {
      this.setCaseId$.next(caseId);
    }
  }

  public async setCurrentCaseById(id:string) {
    if (!id) {
      await this.storageService.remove('selectedCaseId');
    }

    this.setCaseId$.next(id);
  }

  public async setCurrentCaseByMatterId(id:string) {
    this.setMatterId$.next(id);
  }

  public async refreshCases() {
    this.casesRefresh$.next(null);

    return new Promise( (resolve, reject) => {
      setTimeout(
        async () => {
          // TODO: check that the below is accurate, why do we have it in a settimeout and a promise?
          resolve(firstValueFrom(this.cases$.pipe(skip(1))));
        },
        500
      )
    })
  }
}

const transformCaseResponse = (caseResponse: CaseResponse): ClientCase => {
  const legalTeamFormatted: LegalTeamMember[] = caseResponse.matter?.legal_team.map(teamMember => {
    return {
      calendlyLink: teamMember.calendly_link,
      email: teamMember.email,
      id: teamMember.id,
      isActive: teamMember.is_active,
      mediumPhotoUrl: teamMember.medium_photo_url,
      name: teamMember.name,
      outlookOooEndDate: teamMember.outlook_ooo_end_date,
      outlookOooStartDate: teamMember.outlook_ooo_start_date,
      outlookOutOfOffice: teamMember.outlook_out_of_office,
      parentOffice: teamMember.parent_office,
      phone: teamMember.phone,
      role: teamMember.role,
      source: teamMember.source
    }
  });

  return {
    caseDisplayName: caseResponse.case_display_name,
    caseJourneyStatus: caseResponse.case_journey_status,
    caseProgress: caseResponse.case_progress,
    caseProgressBar: caseResponse.case_progress_bar,
    caseStatus: caseResponse.case_progress_status,
    caseType: caseResponse.case_type,
    createdDate: caseResponse.created_date,
    state: caseResponse.incident_state,
    retainerReceivedDate: caseResponse.retainer_received_date,
    intakeId: caseResponse.salesforce_intake_id,
    referenceNumber: caseResponse.salesforce_reference_number,
    status: caseResponse.status,
    updatedDate: caseResponse.updated_date,
    matter: caseResponse.matter ? {
      id: caseResponse.matter?.salesforce_matter_id,
      userId: caseResponse.matter?.user_id,
      incidentState: caseResponse.matter?.incident_state,
      caseType: caseResponse.matter?.case_type,
      litigation: caseResponse.matter?.litigation,
      massTortType: caseResponse.matter?.mass_tort_type,
      financeStatus: caseResponse.matter?.finance_status,
      financeClosedDate: caseResponse.matter?.finance_closed_date,
      matterProgress: caseResponse.matter?.matter_progress,
      stageWhenClosed: caseResponse.matter?.stage_when_closed,
      referenceNumber: caseResponse.matter?.reference_number,
      questionnaireStatus: caseResponse.matter?.questionnaire_status,
      matterStage: caseResponse.matter?.matter_stage,
      matterStatus: caseResponse.matter?.matter_status,
      salesforceCreatedDate: caseResponse.matter?.salesforce_created_date,
      salesforceLastModified: caseResponse.matter?.salesforce_last_modified,
      salesforceIntakeId: caseResponse.matter?.salesforce_intake_id,
      reportingCaseStatus: caseResponse.matter?.reporting_case_status,
      noTreatmentReason: caseResponse.matter?.no_treatment_reason,
      litigationNoTreatmentReason: caseResponse.matter?.litigation_no_treatment_reason,
      displayName: caseResponse.matter?.display_name,
      caseTypeName: caseResponse.matter?.case_type_name,
      source: caseResponse.matter?.source,
      tdRequests: caseResponse.matter?.td_requests,
      mriCompletedC: caseResponse.matter?.mri_completed_c,
      mriScheduledDateC: caseResponse.matter?.mri_scheduled_date_c,
      closed: caseResponse.matter?.closed,
      legalTeam: caseResponse.matter?.legal_team ? legalTeamFormatted : [],
      assistant2Calendly: caseResponse.matter?.assistant2_calendly,
      assistant2Email: caseResponse.matter?.assistant2_email,
      assistant2Name: caseResponse.matter?.assistant2_name,
      assistant2Phone: caseResponse.matter?.assistant2_phone,
      assistant2PicUrl: caseResponse.matter?.assistant2_pic_url,
      assistantCalendly: caseResponse.matter?.assistant_calendly,
      assistantEmail: caseResponse.matter?.assistant_email,
      assistantName: caseResponse.matter?.assistant_name,
      assistantPhone: caseResponse.matter?.assistant_phone,
      assistantPicUrl: caseResponse.matter?.assistant_pic_url,
      attorney2Calendly: caseResponse.matter?.attorney2_calendly,
      attorney2Email: caseResponse.matter?.attorney2_email,
      attorney2Name: caseResponse.matter?.attorney2_name,
      attorney2Phone: caseResponse.matter?.attorney2_phone,
      attorney2PicUrl: caseResponse.matter?.attorney2_pic_url,
      attorney3Calendly: caseResponse.matter?.attorney3_calendly,
      attorney3Email: caseResponse.matter?.attorney3_email,
      attorney3Name: caseResponse.matter?.attorney3_name,
      attorney3Phone: caseResponse.matter?.attorney3_phone,
      attorney3PicUrl: caseResponse.matter?.attorney3_pic_url,
      attorneyCalendly: caseResponse.matter?.attorney_calendly,
      attorneyEmail: caseResponse.matter?.attorney_email,
      attorneyName: caseResponse.matter?.attorney_name,
      attorneyPhone: caseResponse.matter?.attorney_phone,
      attorneyPicUrl: caseResponse.matter?.attorney_pic_url,
      cmanagerPicUrl: caseResponse.matter?.cmanager_pic_url,
      cmanagerPhone: caseResponse.matter?.cmanager_phone,
      cmanagerName: caseResponse.matter?.cmanager_name,
      cmanagerEmail: caseResponse.matter?.cmanager_email,
      cmanagerCalendly: caseResponse.matter?.cmanager_calendly,
      paralegalPicUrl: caseResponse.matter?.paralegal_pic_url,
      paralegalPhone: caseResponse.matter?.paralegal_phone,
      paralegalName: caseResponse.matter?.paralegal_name,
      paralegalEmail: caseResponse.matter?.paralegal_email,
      paralegalCalendly: caseResponse.matter?.paralegal_calendly,
      paralegal2PicUrl: caseResponse.matter?.paralegal2_pic_url,
      paralegal2Phone: caseResponse.matter?.paralegal2_phone,
      paralegal2Name: caseResponse.matter?.paralegal2_name,
      paralegal2Email: caseResponse.matter?.paralegal2_email,
      paralegal2Calendly: caseResponse.matter?.paralegal2_calendly,
    } : undefined
  };
}

export interface LegalTeamMember {
  source: string;
  role: string;
  phone: string;
  parentOffice: string;
  outlookOutOfOffice: string;
  outlookOooStartDate: string;
  outlookOooEndDate: string;
  name: string;
  mediumPhotoUrl: string;
  isActive: boolean;
  email: string;
  calendlyLink: string;
  id: string;
}

export interface CaseMatter {
  id: string;
  userId: number;
  incidentState: string;
  caseType: string;
  litigation: string;
  massTortType: string;
  financeStatus: string;
  financeClosedDate: string;
  matterProgress: string;
  stageWhenClosed: string;
  referenceNumber: string;
  questionnaireStatus: string;
  matterStage: string;
  matterStatus: string;
  salesforceCreatedDate: string;
  salesforceLastModified: string;
  salesforceIntakeId: string;
  reportingCaseStatus: string;
  noTreatmentReason: string;
  litigationNoTreatmentReason: string;
  displayName: string;
  caseTypeName: string;
  source: string;
  tdRequests: string;
  mriCompletedC: string;
  mriScheduledDateC: string;
  closed: boolean;
  legalTeam: LegalTeamMember[];
  assistant2Calendly: string;
  assistant2Email: string;
  assistant2Name: string;
  assistant2Phone: string;
  assistant2PicUrl: string;
  assistantCalendly: string;
  assistantEmail: string;
  assistantName: string;
  assistantPhone: string;
  assistantPicUrl: string;
  attorney2Calendly: string;
  attorney2Email: string;
  attorney2Name: string;
  attorney2Phone: string;
  attorney2PicUrl: string;
  attorney3Calendly: string;
  attorney3Email: string;
  attorney3Name: string;
  attorney3Phone: string;
  attorney3PicUrl: string;
  attorneyCalendly: string;
  attorneyEmail: string;
  attorneyName: string;
  attorneyPhone: string;
  attorneyPicUrl: string;
  cmanagerPicUrl: string;
  cmanagerPhone: string;
  cmanagerName: string;
  cmanagerEmail: string;
  cmanagerCalendly: string;
  paralegalPicUrl: string;
  paralegalPhone: string;
  paralegalName: string;
  paralegalEmail: string;
  paralegalCalendly: string;
  paralegal2PicUrl: string;
  paralegal2Phone: string;
  paralegal2Name: string;
  paralegal2Email: string;
  paralegal2Calendly: string;
}

export interface ClientCase {
  referenceNumber: string;
  caseDisplayName: string;
  caseJourneyStatus: string;
  createdDate: string;
  updatedDate: string;
  retainerReceivedDate: string;
  intakeId: string;
  caseType: string;
  state: string;
  caseStatus: string;
  caseProgress: number;
  caseProgressBar: number;
  status: string;
  matter?: CaseMatter;
  appSchema?: AppSchema,
}

interface CaseResponseLegalTeamMember {
  source: string;
  role: string;
  phone: string;
  parent_office: string;
  outlook_out_of_office: string;
  outlook_ooo_start_date: string;
  outlook_ooo_end_date: string;
  name: string;
  medium_photo_url: string;
  is_active: boolean;
  email: string;
  calendly_link: string;
  id: string;
}

interface CaseResponseMatter {
  salesforce_matter_id: string;
  user_id: number;
  incident_state: string;
  case_type: string;
  litigation: string;
  mass_tort_type: string;
  finance_status: string;
  finance_closed_date: string;
  matter_progress: string;
  stage_when_closed: string;
  reference_number: string;
  questionnaire_status: string;
  matter_stage: string;
  matter_status: string;
  salesforce_created_date: string;
  salesforce_last_modified: string;
  salesforce_intake_id: string;
  reporting_case_status: string;
  no_treatment_reason: string;
  litigation_no_treatment_reason: string;
  display_name: string;
  case_type_name: string;
  source: string;
  td_requests: string;
  mri_completed_c: string;
  mri_scheduled_date_c: string;
  closed: boolean;
  legal_team: CaseResponseLegalTeamMember[];
  assistant2_calendly: string;
  assistant2_email: string;
  assistant2_name: string;
  assistant2_phone: string;
  assistant2_pic_url: string;
  assistant_calendly: string;
  assistant_email: string;
  assistant_name: string;
  assistant_phone: string;
  assistant_pic_url: string;
  attorney2_calendly: string;
  attorney2_email: string;
  attorney2_name: string;
  attorney2_phone: string;
  attorney2_pic_url: string;
  attorney3_calendly: string;
  attorney3_email: string;
  attorney3_name: string;
  attorney3_phone: string;
  attorney3_pic_url: string;
  attorney_calendly: string;
  attorney_email: string;
  attorney_name: string;
  attorney_phone: string;
  attorney_pic_url: string;
  cmanager_pic_url: string;
  cmanager_phone: string;
  cmanager_name: string;
  cmanager_email: string;
  cmanager_calendly: string;
  paralegal_pic_url: string;
  paralegal_phone: string;
  paralegal_name: string;
  paralegal_email: string;
  paralegal_calendly: string;
  paralegal2_pic_url: string;
  paralegal2_phone: string;
  paralegal2_name: string;
  paralegal2_email: string;
  paralegal2_calendly: string;
}

interface CaseResponse {
  salesforce_reference_number: string;
  case_display_name: string;
  case_journey_status: string;
  created_date: string;
  updated_date: string;
  retainer_received_date: string;
  salesforce_intake_id: string;
  case_type: string;
  incident_state: string;
  status: string;
  case_progress: number;
  case_progress_bar: number;
  case_progress_status: string;
  matter?: CaseResponseMatter;
}

export enum MatterStatusOptions {
  NONE = 'Making Progress',
  OPENING = 'Case Opening',
  BUILDING = 'Building Your Case',
  NEGOTIATIONS = 'Negotiations',
  LITIGATION = 'Litigation',
  SETTLED = 'Case Settled',
  TURNED_DOWN = 'Turned Down',
  REFERRED_OUT = 'Referred Out',
}