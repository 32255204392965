import { Injectable, OnDestroy } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { tap, filter, concatMap, debounce, debounceTime, delay, map } from 'rxjs/operators';

export const TOAST_SHORT = 3500;
export const TOAST_LONG = 6000;

@Injectable({
  providedIn: 'root'
})
export class ToastService implements OnDestroy {

  toastSubject: Subject<ToastOptions> = new Subject<ToastOptions>();
  toastOptions$: Observable<ToastOptions> = this.toastSubject.asObservable();
  groupedByDebounceSubscription: Subscription;
  debouncedSubscription: Subscription;

  constructor(private toastCtrl: ToastController) {
    // PROMISE BASED (not timing - required manual dismiss works too)
    this.groupedByDebounceSubscription = this.toastOptions$
      .pipe(
        // tap( () => console.log('TOAST - BEFORE PRESENT') ),
        concatMap(  (options:ToastOptions) => this.present(options)),
      )
      .subscribe();
  }


  ngOnDestroy(): void {
    this.groupedByDebounceSubscription.unsubscribe();
  }






  public show(message: string, options: ToastOptions = {}) {
    this.toastSubject.next(<ToastOptions>{
            message,
            animated: true,
            color: 'primary',
            cssClass: 'toast-service',
            duration: TOAST_SHORT,
            position: 'top',
            ...options
          },
    );
  }

  public showNoDefaultOptions(options: ToastOptions) {
    this.toastSubject.next(options);
  }



  public showSuccessToast(message: string, options: ToastOptions = {}) {
    this.toastSubject.next({
            message,
            animated: true,
            color: 'success',
            cssClass: 'toast-service',
            duration: TOAST_SHORT,
            position: 'top',
            ...options
          },
    );
  }

  public showFailureToast(message: string, options: ToastOptions = {}) {
    this.toastSubject.next({
            message,
            animated: true,
            color: 'danger',
            cssClass: 'toast-service',
            duration: TOAST_LONG,
            position: 'top',
            ...options
          },
    );
  }

  dismiss = () => {
    return this.toastCtrl.dismiss();
  }


  async present(options: ToastOptions) {
    const toast = await this.toastCtrl.create(options);
    // console.log('TOAST - create', toast);

    const presented = await toast.present();
    // console.log('TOAST - present ', presented);
    const didDismiss = await toast.onDidDismiss();
    // console.log('TOAST - didDismiss:', didDismiss);
    return await toast;
  }
}
