import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { AlertButton, ModalController } from '@ionic/angular';

@Component({
  selector: 'mm-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {

  @Input() header: string = '';
  @Input() buttons: AlertButton[] = [];
  @Input() message?: string = '';
  @Input() showCloseButton?: boolean = false;
  @Input() showAlertIcon?: boolean = true;
  @Input() projectedContent?: TemplateRef<any>;
  
  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    console.log("Alert Values in here", this.header, this.message, this.showCloseButton, this.buttons);
    this.showAlertIcon = this.showAlertIcon ?? true;
  }


  async close() { 
    const close: string = "Modal Removed";
    await this.modalController.dismiss(close);
  };

  handler(buttonHandler: Function) {
    buttonHandler();
    // this.close()
  }

}
