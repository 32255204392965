import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, firstValueFrom, from, Observable, of, zip } from 'rxjs';
import { catchError, delay, filter, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService, AuthState, JWT } from './auth.service';
import { indicate } from './util/rxjs/operators';
import jwtDecode from 'jwt-decode';

export interface LegalTeamMember {
  source: string;
  id: string;
  name: string;
  role: string;
  email?: string;
  sortOrder?: number;
  phone: string;
  calendlyLink: string;
  picture: string;
  parentOffice?: string;
  isActive: string;
}

export interface MatterResponse {
  id: string,
  source: string,
  stageWhenClosed: string,
  createdDate: string,
  referenceNumber: string,
  questionnaireStatus: string,
  financeClosedDate: string,
  massTortType: string,
  intakeId?: string,
  caseType?: string;
  lastModified: string,
  // matterProgress: string | number,  //DO NOT PULL FROM SF API
  matterStatus: string,
  reportingCaseStatus: string,
  matterStage: string,
  displayName?: string,
  litigation?: string;
  noTreatmentReason?: string;
  litigationNoTreatReason?: string;
  clientPortalTasks: string;
}

//?q=SELECT Id, Incident_state__c FROM litify_pm__matter__c WHERE litify_pm__Client__c = '0012300000e750yAAA'
interface MatterSoqlResponseItem {
  Id: string;
  Incident_State__c: string;
  Finance_Status__c: string;
  TD_Requests__r: TDRequestsResponseItem;
}
interface MatterSoqlResponse {
  totalSize: number,
  done: boolean,
  records: MatterSoqlResponseItem[]
}
interface TDRequestsResponseItem {
  totalSize: number,
  done: boolean,
  records: TDRecord[]
}
interface TDRecord {
  attributes: object;
  Call_Completed_Date_Time__c: Date;
}


export interface Matter extends MatterResponse {
  closed: boolean;
  // caseProgress: number,
  // caseProgressBar: number,
  legalTeam: LegalTeamMember[];
  state: string;
  status: string;
  turnedDownDate: Date;
  financeStatus: string;
}

// http.get('.../account/api/matters/a1n1J000002c0xgQAA/intakeId')
export interface IntakeMatter {
  source: string;
  intakeId: string;
  id: string;
}

export interface CaseData {
  // caseList: Matter[];
  // currentCase: Matter;
  // selectCaseDisabled: boolean;
  matters: Matter[];
  error?: string;
}

export enum CaseStatusLabels {
  NONE = 'Making Progress',
  OPENING = 'Launch',
  BUILDING = 'Build',
  NEGOTIATIONS = 'Negotiate',
  LITIGATION = 'Litigate',
  SETTLED = 'Settled',
  TURNED_DOWN = 'Turned Down',
  REFERRED_OUT = 'Case Referred Out',
}

export enum MatterStatusOptions {
  NONE = 'Making Progress',
  OPENING = 'Case Opening',
  BUILDING = 'Building Your Case',
  NEGOTIATIONS = 'Negotiations',
  LITIGATION = 'Litigation',
  // SETTLED = 'Settled',  // 'Case Settled',
  SETTLED = 'Case Settled',
  TURNED_DOWN = 'Turned Down',
  REFERRED_OUT = 'Referred Out',
}

export const MassTortCaseTypes = [
  'Ohio Train Derailment',
  'Hair Relaxer'
];

@Injectable({
  providedIn: 'root'
})
export class MatterService {
  private matterRefreshSubject$: BehaviorSubject<null> = new BehaviorSubject(null);

  public mattersLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public mattersInfoWithoutLegalTeam$: Observable<{matters:Matter[], error:string}> = this.matterRefreshSubject$.pipe(
    switchMap(() => this.authSvc.currentState$),
    map((authState:AuthState) => authState.jwt ),
    filter((jwt:JWT) => !!jwt), // if there's no JWT, don't proceed
    switchMap((jwt: JWT) =>
      (this.getMattersHTTP(jwt?.account_id)
        .pipe(
          indicate(this.mattersLoading$),
          map((matters: MatterResponse[]) => matters.filter(
            // Litigation is NOT 'Personal Injury' and  Case Type is NOT 'Automobile Accident' OR 'Trucking'
            // OR
            // Mass Tort type is one of the Mass Tort Case Types
            (matter: Matter) =>
              MassTortCaseTypes.includes(matter.massTortType)
              || (matter.litigation === 'Personal Injury' && ['Automobile Accident', 'Trucking'].includes(matter.caseType))
              || (matter.litigation === 'Premises Liability' && ['Slip and Fall', 'Trip and Fall', 'Staircase', 'General Liability', 'General Injury', 'Animal Incident', 'Salon', 'Negligent Security', 'NY Worksite Injury', 'Food Poisoning', 'Bed Bugs'].includes(matter.caseType))
          )),
          tap( (matters: Matter[]) => console.log('*******MattersResponse:', matters) ),
        )
      )
      .pipe(
        map((matters: MatterResponse[]) => matters.map((matter: Matter) => ({
          ...matter,
          caseType: MassTortCaseTypes.includes(matter.massTortType) ? `${matter.massTortType}` : `${matter.caseType}`,
        }) )),
      )
      .pipe(
        map((matters: Matter[]) => matters.map((matter: Matter) => ({
          ...matter,
          closed: this.isMatterClosed(matter),

        }),
        )),
      )
      .pipe(
        map((matters: Matter[]) => ({ matters, error: matters.length === 0 ? 'no-matters' : null })),
        catchError((err: any) => of({ matters: <Matter[]>[], error: 'http-error' })),


      ),
    ),
    shareReplay(1),
  );

  public legalTeamLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  //This version, is the "final version", which appends a legalTeam to each matter
  public mattersInfo$: Observable<{matters:Matter[], error:string}> = this.mattersInfoWithoutLegalTeam$
    .pipe(
      switchMap((mattersInfo: {matters: Matter[], error: string|null}) =>                                  // let's not even send a matter ID if the case is CLOSED:
        this.getAllTeamMembers(mattersInfo.matters.map((cl: Matter) => cl.closed ? null : cl.id))
          .pipe(
            indicate(this.legalTeamLoading$),
            // startWith([]),

            map((reqs: Array<LegalTeamMember[]>) => ({
              ...mattersInfo,
              matters: mattersInfo.matters.map(
                (cl:Matter, index:number) => {
                  return ({
                    ...cl,
                    legalTeam: (reqs[index]?.length >= 0 ? reqs[index] : null),
                  })
                }
              ),
            })),
          ),
      ),
    );


  /**
   *
   * METHODS
   *
   */
  constructor(
    private http: HttpClient,
    private authSvc: AuthService,
  ) { }

  public forceRefresh() {
    this.matterRefreshSubject$.next(null);
  }

  public async getLatestMatterInfo() {
    console.log('MatterService.getLatestMatterInfo()');
    return firstValueFrom(
      this.mattersInfo$.pipe(
        take(1),
        delay(1000),
      )
    );
  }

  public getIntakeMatter(id: string): Observable<IntakeMatter> {
    return this.http.get(
      `${environment.api.baseUrl}/${environment.api.mattersUri}/${id}/intakeId`
    )
    .pipe(
      map( (matters: IntakeMatter[]) => matters.length ? matters[0] : null ),
    )
  }

  public getMatter(id: string): Observable<Matter> {
    return this.getMattersHTTP(id)
    .pipe(
      map( (matters: Matter[]) => matters.length ? matters[0] : null ),
    )
  }


  public readonly closedMatterStages: string[] = (Object.values(MatterStatusOptions).map((s: string) => s)).splice(-3);
  public isMatterClosed(matter: Matter): boolean {
    return this.closedMatterStages.includes(matter.status);
  }



  /**
   * two HTTP requests - splice together results from the /portal/matters API and a SOQL query
   */
  private getMattersHTTP(id: string): Observable<Matter[]> {
    return combineLatest([
      (
        // <Observable<Matter[]>>
        this.http.get<MatterResponse[]>(
        `${environment.api.baseUrl}/${environment.api.mattersUri}/${id}?sort=-createdDate`
      ).pipe(
        map( (matters: MatterResponse[]) => matters.map( (m:MatterResponse) => ({
          id: m.id,
          source: m.source,
          stageWhenClosed: m.stageWhenClosed,
          createdDate: m.createdDate,
          referenceNumber: m.referenceNumber,
          questionnaireStatus: m.questionnaireStatus,
          financeClosedDate: m.financeClosedDate,
          massTortType: m.massTortType,
          intakeId: m.intakeId,
          caseType: m.caseType,
          lastModified: m.lastModified,
          // matterProgress: m.matterProgress, //DO NOT PULL FROM SF API
          matterStatus: m.matterStatus,
          reportingCaseStatus: m.reportingCaseStatus,
          matterStage: m.matterStage,
          displayName: m.displayName,
          litigation: m.litigation,
          noTreatmentReason: m.noTreatmentReason,
          litigationNoTreatReason: m.litigationNoTreatReason,
          clientPortalTasks: m.clientPortalTasks,
        }) ) )
      )),

      this.http.post<MatterSoqlResponse>(
        `${environment.api.baseUrl}/data`,
        `?q=SELECT Id, Incident_state__c, Case_Type_Name__c, Mass_Tort_Type__c, Litigation__c, Finance_Status__c, (SELECT Call_Completed_Date_Time__c FROM TD_Requests__r) FROM litify_pm__matter__c WHERE litify_pm__Client__c = '${id}'`
      ).pipe(
        map( (mr:MatterSoqlResponse) => mr.records)
      )

    ]).pipe(
      map( ([api, soql]: [MatterResponse[], MatterSoqlResponseItem[]]) => ({api, soql}) ),
      map( ({api, soql}: {api: MatterResponse[], soql: MatterSoqlResponseItem[]} ) => api.map(
        (matter: MatterResponse, index: number) =>  ({
          ...matter,
          closed: false,
          legalTeam: [],
          // state: soql[index].Incident_State__c,
          state: soql.find( (item:MatterSoqlResponseItem) => item.Id===matter.id)?.Incident_State__c,
          status: '',
          // There can be multiple TD (turned down) requests for a given matter, but only one of them will have a "Call_Completed_Date_Time__c" field populated if the TD request was accepted
          turnedDownDate: soql.find((item:MatterSoqlResponseItem) => item.Id === matter.id)?.TD_Requests__r?.records.find(({Call_Completed_Date_Time__c}) => !!Call_Completed_Date_Time__c)?.Call_Completed_Date_Time__c,
          financeStatus: soql.find((item:MatterSoqlResponseItem) => item.Id === matter.id)?.Finance_Status__c
        })
      ) ),
    );

  }

  private getTeamMembersHTTP(matterId: string, idx: number): Observable<LegalTeamMember[]> {
    return this.http.get<LegalTeamMember[]>(`${ environment.api.baseUrl }/teamMembers/${ matterId }`).pipe(
      // tap( (team: LegalTeamMember[]) => console.warn(`LegalTeam REQUEST for Matter #${matterId} (size: ${team.length})`, team) ), //TODO MO: We should not get all cases team members
    );
  }

  private getTeamMembersSortOrdering(member: LegalTeamMember): number {
    let result:number = 0;
    switch(member.role) {
      case 'Principal Attorney':
        result = 1;
        break;
      case 'Principal Attorney 2':
        result = 2;
        break;
      case 'Paralegal':
        result = 3;
        break;
      case 'Paralegal 2':
        result = 4;
        break;
      case 'Case Manager':
        result = 5;
        break;
      case 'Client Experience Manager':
        result = 6;
        break;
    }
    return result;
  }

  private filterActiveTeamMembers(member: LegalTeamMember): Boolean {
    return member.isActive === 'true';
  }

  private getAllTeamMembers(ids: string[]): Observable<LegalTeamMember[][]> {
    return (zip(ids.map((matterId: string, idx: number) => matterId ? this.getTeamMembersHTTP(matterId, idx) : of([])))
      .pipe(
        startWith([]),
      ))
      .pipe(
        map((allTeams: LegalTeamMember[][]) => allTeams.map(
          (teamMembers: LegalTeamMember[]) => teamMembers.map(
            (member: LegalTeamMember) => ({ ...member, sortOrder: this.getTeamMembersSortOrdering(member) }),
          ).filter(this.filterActiveTeamMembers),
        )),
        map((allTeams: LegalTeamMember[][]) => allTeams.map(
          (teamMembers: LegalTeamMember[]) => teamMembers.sort((a: LegalTeamMember, b: LegalTeamMember) => a.sortOrder -
            b.sortOrder),
        )),
      );
  }

}
