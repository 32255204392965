import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
import { Observable, Subject, catchError, delay, from, iif, map, mergeMap, of, retry, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { indicate } from './util/rxjs/operators';
import { MatterStatusOptions } from './matter.service';
import { ClientCaseCard } from './case.service';


interface MappingResponse {
  display_name: string
}

interface CaseStatusMappingItem {
  matterID: string;
  mapping: string;
}


@Injectable({
  providedIn: 'root'
})
export class CaseStatusMappingsService {
  private userApi = `${environment.api.edwinBaseUrl}/case_status_mappings/find`
  private CASE_STATUS_MAPPINGS_KEY = 'caseStatusMappings';

  public caseStatusMappingLoading$ = new Subject<boolean>();
  
  constructor(
    private http: HttpClient,
    private storageService: StorageService,   
  ) { }


  public getCaseStatusMappingFromHTTP(cc:ClientCaseCard, isPrevCached:boolean): Observable<string> {
    if(!cc.matter) {
      return of(MatterStatusOptions.OPENING);
    }
    const matter = cc.matter;
    const matterStatus:string = matter.reportingCaseStatus;
    const matterStage:string = matter.matterStage;
    const caseType:string = matter.caseType;
    const financeStatus: string = matter.financeStatus;
    const turnedDownDate: Date = matter.turnedDownDate;
    return this.http.post<MappingResponse>(this.userApi, {
      case_type: caseType,
      matter: {
        stage: matterStage,
        finance_status: financeStatus,
        call_completed_time: turnedDownDate
      },
      case_status: matterStatus,
      is_prev_cached: isPrevCached
    })
    .pipe(
      catchError( () => of({display_name: 'Making Progress'})),
      indicate(this.caseStatusMappingLoading$),
      map( (result:MappingResponse) => result.display_name ),
    );
  }



  public async getCaseStatusMappingFromStorage(matterID: string): Promise<string> {
    const cachedCaseStatusMappings:CaseStatusMappingItem[] = await <Promise<CaseStatusMappingItem[]>>this.storageService.get(this.CASE_STATUS_MAPPINGS_KEY) ?? []
    const caseStatusMappingIndex:number = cachedCaseStatusMappings.map( (item: CaseStatusMappingItem) => item.matterID ).indexOf(matterID);
    const caseStatusMapping:string = cachedCaseStatusMappings[caseStatusMappingIndex] ? cachedCaseStatusMappings[caseStatusMappingIndex].mapping : '';
    return caseStatusMapping;
  }


  public async setCaseStatusMapping(cc:ClientCaseCard, mapping: string): Promise<string> {
    const matterID: string = cc.matter?.id;
    const caseStatusMappings:CaseStatusMappingItem[] = await this.storageService.get(this.CASE_STATUS_MAPPINGS_KEY) ?? []

    if( !caseStatusMappings.map(csm => csm.matterID).includes(matterID) ) {
      caseStatusMappings.push({matterID, mapping});
    }
    return ( this.storageService.set(this.CASE_STATUS_MAPPINGS_KEY, [...caseStatusMappings]) )
    .then(
      () => mapping
    ).catch(
      () => 'ERROR'
    );
  }


                        //TODO case: ClientCaseCard ??
  public getCaseStatusMapping(cc: ClientCaseCard): Observable<string> {
    const matterID: string = cc.matter?.id;

    return from(this.getCaseStatusMappingFromStorage(matterID)).pipe(
      // tap( (caseStatusMapping: string) => console.log('AccountService: caseStatusMappingStorage():', caseStatusMapping)),
      mergeMap( (caseStatusMapping: string) => 
        this.getCaseStatusMappingFromHTTP(cc, (caseStatusMapping!=='')) //isPrevCached: !=='' is true, ==='' is false
        .pipe( 
          // tap( (caseStatusMappingCheck: string) => console.log('CaseStatusMappingsService: getCaseStatusMapping():', matterID, caseStatusMappingCheck)),
          mergeMap( (caseStatusMappingNEW: string) => caseStatusMappingNEW!=='' ? from(this.setCaseStatusMapping(cc, caseStatusMappingNEW)) : of(caseStatusMapping) ),
        )
      ),
      // tap( (caseStatusMapping: string) => console.log('CaseStatusMappingsService: FINAL getCaseStatusMapping():', caseStatusMapping)),
    )
  }

}
