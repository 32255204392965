<ion-content>
  <ion-row>
    <img src="assets/welcome/blue-background-logo.png" class="blue-background-logo"/>
  </ion-row>
  <div class="scheduledMaintenanceContainer">
    <ion-row class="iconContainer">
      <fa-icon class="fa-2x icon" [icon]="faScrewdriverWrench"></fa-icon>
      <span class="backgroundCircle"></span>
    </ion-row>
    <ion-row>
      <p class="message">Our Client Portal is undergoing some scheduled maintenance from <span>{{ startTime }}</span> to <span>{{ endTime }}</span>.
        Thank you for your patience!
      <br />
      <br />
        Need immediate assistance?
      <br />
        Call <a href="tel:{{phone}}">{{phone}}</a>
      </p>
    </ion-row>
  </div>
</ion-content>
