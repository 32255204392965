import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular'; //import { Storage } from '@capacitor/storage';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, shareReplay, tap } from 'rxjs';

const NOTIFICATION_TOGGGLES_STORAGE_KEY='notification-toggles'


export interface NotificationSettings {
  messages: boolean;
  caseStatus: boolean;
  painLevel: boolean;
  treatments: boolean;
  tasks: boolean;
  days: boolean[];  //7 days / 7 booleans
}






/**
 * ********************************************************************************************************************************************
 * ********************************************************************************************************************************************
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationSettingsService { //implements INotificationSettingService {
  /**
   * *******************
   * MEMBERS
   * *******************
   */
  private messageState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private caseStatusState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private painLevelState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private treatmentState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private tasksState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private daysOfWeekState$: BehaviorSubject<boolean[]> = new BehaviorSubject<boolean[]>([false, false, false, false, false, false, false]);

  public toggleStates$: Observable<NotificationSettings> = 
    combineLatest([
      this.messageState$,
      this.caseStatusState$,
      this.painLevelState$,
      this.treatmentState$,
      this.tasksState$,

      //Not a notification, but options for painLevelState when true
      this.daysOfWeekState$,
    ])
    .pipe(
      // skip(1),
      map( ([
              messages, caseStatus, painLevel,
              treatments, tasks,
              days
      ]) =>
        <NotificationSettings>{
          messages,
          caseStatus,
          painLevel,
          treatments,
          tasks,

          days,
        }
      ),
      // tap( (states: NotificationSettings) => console.log('toggleStates$:', states) ),
      tap( async (states: NotificationSettings) => await this.storeNotificationToggleStates(states) ),
      shareReplay(1),
  );



  

  /**
   * *******************
   * METHODS
   * *******************
   */
  constructor(
    private storage: Storage,
  ) {
    this.loadNotificationToggleStates().then(
      (state: NotificationSettings) => {
        if(state) {
          console.log('Notification Settings ARE in storage');
          // this.messageState$.next(state.messages);
          // this.caseStatusState$.next(state.caseStatus);
          // this.painLevelState$.next(state.painLevel);
          // this.treatmentState$.next(state.treatments);
          // this.tasksState$.next(state.tasks);
          this.messageState$.next(state.messages===undefined ? true : state.messages);
          this.caseStatusState$.next(state.caseStatus===undefined ? true : state.caseStatus);
          this.painLevelState$.next(state.painLevel===undefined ? true : state.painLevel);
          this.treatmentState$.next(state.treatments===undefined ? true : state.treatments);
          this.tasksState$.next(state.tasks===undefined ? true : state.tasks);

          try {
            this.setPainLevelDays(state.days);
          } catch(e) {
            console.log('unable to assign settings for all 7 days of the week', e);
          }
        } else {
          console.log('Notification Settings are NOT in storage');
          // Kick it off with default settings
          this.messageState$.next(true);
          this.caseStatusState$.next(true);
          this.painLevelState$.next(true);
          this.treatmentState$.next(true);
          this.tasksState$.next(true);
          this.setPainLevelDays([
            true, //Sunday
            true, //Monday
            true, //Tuesday
            true, //Wednesday
            true, //Thursday
            true, //Friday
            true  //Saturday
          ]);
      }
    })
  }

  setPainLevelDays( daysOfWeek: boolean[] ) {
    if(daysOfWeek.length==7) {
      this.daysOfWeekState$.next( daysOfWeek )
    }
    else {
      throw('setPainLevelDays(): 7 booleans are required in this array - one for each day of the week');
    }
  }


  private async loadNotificationToggleStates() {
    await this.storage.create();
    return this.storage.get(NOTIFICATION_TOGGGLES_STORAGE_KEY);
  }

  private async storeNotificationToggleStates(states?: NotificationSettings) { //either pass in a value or use latest
    return this.storage.set(NOTIFICATION_TOGGGLES_STORAGE_KEY, states ?? firstValueFrom(this.toggleStates$));
  }


  flipMessages() {
    this.messageState$.next( !this.messageState$.getValue() );

  }

  flipCaseStatus() {
    this.caseStatusState$.next( !this.caseStatusState$.getValue() );
  }

  flipPainLevels() {
    this.painLevelState$.next( !this.painLevelState$.getValue() );
  }

  flipTreatments() {
    this.treatmentState$.next( !this.treatmentState$.getValue() );
  }

  flipTasks() {
    this.tasksState$.next( !this.tasksState$.getValue() );
  }
}
