import { Injectable, TemplateRef } from '@angular/core';
import { AlertButton, ModalController } from '@ionic/angular';
import { AlertComponent } from '../components/alert/alert.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private modalCtrl: ModalController,) { }

  public createAlert(
    header: string, 
    buttons: AlertButton[], 
    message?: string, 
    showCloseButton?: boolean, 
    showAlertIcon?: boolean, 
    projectedContent?: TemplateRef<any>
    ){
    console.log("Alert Values", header, message, showCloseButton, buttons);

    this.modalCtrl.create({
      component: AlertComponent,
      componentProps: {
        header,
        buttons,
        message,
        showCloseButton,
        showAlertIcon,
        projectedContent
      },
      backdropDismiss: false,
      cssClass: "alert-modal"
    }).then( (modal) => modal.present() )
  }


  closeAlert() {
    this.modalCtrl.dismiss();
  }
}
