import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap, BehaviorSubject, of, delay } from 'rxjs';
import { CaseService, ClientCase, LegalTeamMember } from 'src/app/services/case.service';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';
import { ScheduledMeetingComponent } from 'src/app/pages/legal-team-member/contact/scheduled-meeting/scheduled-meeting.component';
import { indicate } from './util/rxjs/operators';

export type ScheduledCall = {
  id: string;
  case_team_email: string;
  case_team_name: string;
  case_team_role: string;
  topic: string;
  intake_id: string;
  matter_number: string;
  case_staff_id: string;
  appointment_date: string;
  rescheduled_date: string | null;
  created_at: string;
  updated_at: string;
  user_notes: string;
  invite_status: string;
  litify_event_id: string | null;
};

export type ScheduledCallPayload = {
  case_team_name: string;
  case_team_role: string;
  case_team_email: string;
  client_name: string;
  topic: string;
  intake_id: string;
  case_staff_id: string;
  appointment_date: string;
  user_notes: string | null;
};

export type Schedule = TimeSlot[];


export type TimeSlot = {
  start: string;
  end: string;
};

@Injectable({
  providedIn: 'root',
})
export class CallSchedulingService {
  constructor(
    private http: HttpClient,
    private caseService: CaseService,
    public modalController: ModalController,
  ) {}

  private upcomingCallRefreshSubject$: BehaviorSubject<null> = new BehaviorSubject(null);
  public upcomingCallRefresh$ = this.upcomingCallRefreshSubject$.asObservable();

  public forceRefreshUpcomingCall() {
    this.upcomingCallRefreshSubject$.next(null);
  }

  public upcomingCall$: Observable<ScheduledCall> = this.upcomingCallRefreshSubject$
  .pipe(
    switchMap( () => this.caseService.selectedCase$ ),
    switchMap((clientCase: ClientCase) => this.getUpcomingCallHTTP(clientCase.intakeId)),
  );

  public getUpcomingCallHTTP(intakeId: string): Observable<ScheduledCall> {
    return this.http.get<ScheduledCall>(
      `${environment.api.edwinBaseUrl}/case_team/upcoming_call?intake_id=${intakeId}`,
    );
  }

  public createScheduledCallMOCK(payload: ScheduledCallPayload): Observable<ScheduledCall> {
    return of({
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      case_team_email: "string",
      case_team_name: "string",
      case_team_role: "string",
      topic: "string",
      intake_id: "string",
      matter_number: "string",
      case_staff_id: "string",
      appointment_date: "2024-11-20T23:21:41.544Z",
      rescheduled_date: "2024-11-20T23:21:41.544Z",
      created_at: "2024-11-20T23:21:41.544Z",
      updated_at: "2024-11-20T23:21:41.544Z",
      user_notes: "string",
      invite_status: "string",
      litify_event_id: "string"
    }).pipe(
      delay(4000),
      indicate(this._createScheduleLoading$),
    );
  }

  public createScheduledCall(payload: ScheduledCallPayload): Observable<ScheduledCall> {
    return this.http.post<ScheduledCall>(`${environment.api.edwinBaseUrl}/case_team/scheduled_calls`, payload).pipe(
      delay(4000),
      indicate(this._createScheduleLoading$),
    );
  }

  private _scheduleLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public scheduleLoading$: Observable<boolean> = this._scheduleLoading$.asObservable();
  private _createScheduleLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public createScheduleLoading$: Observable<boolean> = this._createScheduleLoading$.asObservable();


  public getTeamMemberSchedule(teamMember: LegalTeamMember, intakeId: string): Observable<TimeSlot[]> {
    return this.http.get<TimeSlot[]>(
      `${environment.api.edwinBaseUrl}/case_team/schedule?case_staff_email=${teamMember.email}&intake_id=${intakeId}`,
    ).pipe(
      indicate(this._scheduleLoading$)
    );
  }

  public openMeetingModal = (call: ScheduledCall): Promise<void> => {
    return this.modalController
      .create({
        component: ScheduledMeetingComponent,
        componentProps: {
          call,
        },
        cssClass: 'full-screen',
      })
      .then((modal) => modal.present());
  };

  public closeMeetingModal = (): Promise<boolean> => {
    return this.modalController.dismiss();
  };
}
