import { Injectable, NgZone } from '@angular/core';
import { App, AppLaunchUrl, URLOpenListenerEvent } from '@capacitor/app';
import { BehaviorSubject, Observable, filter, tap } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DeeplinksService {
  private _bypassDeepLinksOnNotificationActionLaunched: boolean = false;

  private routeSubject$: BehaviorSubject<string|null> = new BehaviorSubject<string|null>(null);
  public route$: Observable<string> = this.routeSubject$
  .asObservable()
  .pipe(
    tap(  (route: string|null) => console.log('DeepLinkService route$:', route) ),
    filter( (route: string|null) => route!==null),
  );

  constructor(
    private zone: NgZone,
  ) { }

  getPath(url:string): string {
    const path = new URL(url).pathname.split('/').pop()
    console.log("getPath() - RETURNED PATH:", path)
    return path;
  }

  initialize() {
    console.log('DeepLinksService.initialize()')
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        console.log('DeepLinksService.initialize() - App.addListener(appUrlOpen) event.url: ', event.url);

        const appPath = this.getPath(event.url);
        console.log('DeepLinksService.initialize() - getPath(event.url): ',  appPath)
        if (appPath) {
          this.pushRoute(appPath, true); // this.router.navigateByUrl(appPath);
        }
      });
    });
  }


  set bypassDeepLinks(_bypassDeepLinks: boolean) {
    console.warn('SET bypassDeepLinks:', this._bypassDeepLinksOnNotificationActionLaunched);
    this._bypassDeepLinksOnNotificationActionLaunched = _bypassDeepLinks;
  }
  get bypassDeepLinks() {
    console.warn('GET bypassDeepLinks:', this._bypassDeepLinksOnNotificationActionLaunched);
    return this._bypassDeepLinksOnNotificationActionLaunched;
  }

  pushRoute(route: string, routeIsFromDeepLink=false) {
    if(!routeIsFromDeepLink || !this._bypassDeepLinksOnNotificationActionLaunched) {
      this.routeSubject$.next(route);
    }
  }

}
