import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DefaultPrivacyLevel, datadogRum } from '@datadog/browser-rum'
import { App } from '@capacitor/app';
import { environment } from 'src/environments/environment';
import { Account } from './account.service';

@Injectable({
  providedIn: 'root',
})
export class DatadogService {
  constructor(
    private platform : Platform,
  ) {}

  setUser(account:Account) {
    datadogRum.setUser({
      id: account.id,
      name: account.clientName,
      email: account.clientEmail
    });
  }

  async init(): Promise<void> {
    if(location.host.includes('localhost')) {
      console.log('SKIPPING DatadogService.init()');
      return;
    }

    const appInfo = await App.getInfo().catch( (e) => {
      console.error('No AppInfo for acquiring version - this must be the web app, not the native mobile.\nSetting version to null\nError:\n\n', e);
      return {
        version:null,
      }
    });

    console.log('DatadogService.init() - Setting version from AppInfo:', appInfo);
    environment.monitoring.datadog.version = appInfo.version ?? '0.0.0';

    if(environment.devHacks.datadog.showEnvData) {
      console.info('DataDog configs:', environment.monitoring.datadog)
    }

    try {
      datadogRum.init({
        ...environment.monitoring.datadog,
        // Specify a version number to identify the deployed version of your application in Datadog 
        version: appInfo.version, 
        
        // allowFallbackToLocalStorage: true // this got iOS working, but with a messed up session replay
        // defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [environment.api.edwinBaseUrl, (url) => url.startsWith(environment.api.edwinBaseUrl)],
      });
      console.log('Datadog initialized')
    } catch (err) {
      console.error(`Error initializing Datadog RUM: ${err}`);
    }
  }
}