import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  share,
  Subject,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { format, isToday, isYesterday } from 'date-fns';
import { CaseService, ClientCase } from './case.service';
import { indicate } from './util/rxjs/operators';

const {
  api: { baseUrl, messagesUri },
} = environment;
const {
  messages: { shortInterval, longInterval },
} = environment;


@Injectable({
  providedIn: 'root',
})
export class MessageService implements OnDestroy {
  private pollingInterval$: BehaviorSubject<number> = new BehaviorSubject(longInterval);
  private api: string = `${baseUrl}/${messagesUri}`;

  public selectedCase$:Observable<ClientCase> = this.caseService.selectedCase$;
  private stopPolling$: Subject<null> = new Subject<null>();
  public messagesInitialLoading$ = new BehaviorSubject<boolean>(false);
  messagesToDisplay$:BehaviorSubject<TextMessage[]> = new BehaviorSubject<TextMessage[]>([]);
  messagesToCompare$:BehaviorSubject<TextMessage[]> = new BehaviorSubject<TextMessage[]>([]);
  hasNewMessages$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private selectedCaseMatterId: string = '';
  private totalNumberOfMessages: number = 0;

  interval$:Observable<number> = this.pollingInterval$.pipe(
    switchMap( (seconds:number) => timer(0, seconds))
  )
  
  public initialMessages$:Observable<TextMessage[]> = this.selectedCase$.pipe(
    filter((cd) => cd.matter!==null ),
    switchMap((caseData:ClientCase) => this.getMessages(caseData.matter.id)),
  );

  public latestMessages$:Observable<TextMessage[]> = this.selectedCase$.pipe(
    filter((cd) => cd!==null ),
    filter((cd) => cd.matter!==undefined ),
    switchMap(cd => this.interval$.pipe(
      switchMap( (i) => this.getLatestMessages(cd.matter.id) )
    ))
  );

  constructor(
    private caseService: CaseService,
    private http: HttpClient,
  ) {}

  ngOnDestroy() {
    this.cancelPolling();
  }

  setLongPollingInterval() {
    this.pollingInterval$.next(longInterval);
  }
  setShortPollingInterval() {
    this.pollingInterval$.next(shortInterval);
  }

  public startPolling(): void {
    // Get Latest Messages of the Selected Case
    this.startGettingLatestMessages().subscribe((messages: TextMessage[]) => {
      this.messagesToCompare$.next(messages);
      this.compareNewMessages();
    });

    // Get Initial Messages of the Selected Case
    this.getSelectedCaseMessages()
    .pipe(
      map(MessageService.transformMessages),
      share()
    )
    .subscribe((messages: TextMessage[]) => {
      this.totalNumberOfMessages = messages.length;
      this.messagesToDisplay$.next(messages);
    });
  }

  public cancelPolling(): void {
    this.stopPolling$.next(null);
    this.stopPolling$.complete();
  }

  public sendMessage(message: string): void {
    //TODO:  use a BehaviorSubject.next() to push value into Observable and call postMessage?
    this.postMessage(message, this.selectedCaseMatterId).subscribe((response) => {
      this.pollingInterval$.next(shortInterval);
      console.log('MessagesPage: MessageService.sendMessage response', response);
    });
  }

  public postMessage(message: string, matterId: string): Observable<PostResponse> {
    return this.http
      .post<PostResponse>(`${this.api}/${matterId}`, <SendTextMessage>{
        matterId: matterId,
        body: message,
        messageSource: 'Client Portal',
      })
  }

  private getSelectedCaseMessages(): Observable<TextMessage[]> {
    return this.selectedCase$.pipe(
      filter( (cd) => cd!==null ),
      filter( (cd) => cd.matter!==undefined ),
      switchMap( (cd) =>  {
        this.selectedCaseMatterId = cd.matter.id;
        return this.getMessages(cd.matter.id).pipe(indicate(this.messagesInitialLoading$));
      })
    )
  }

  private startGettingLatestMessages(): Observable<TextMessage[]> {
    console.log('MessagesPage: MessageService.startGettingLatestMessages');
    return this.latestMessages$.pipe(
      takeUntil(this.stopPolling$),
    )
  }

  private getMessages(matterId: string, queryParams: string=''): Observable<TextMessage[]> {
    return this.http.get<TextMessage[]>(`${this.api}/${matterId}?${queryParams}`);
  }


  private getLatestMessages(matterId: string): Observable<TextMessage[]> {
    let offsetValue = 0;
    if (this.totalNumberOfMessages > 5) {
      offsetValue = this.totalNumberOfMessages - 5;
    }
    return this.getMessages(matterId, `offset=${offsetValue}&limit=10&mmispolling=true`);
  }

  private compareNewMessages(): void {
    const messages = this.messagesToDisplay$.getValue();
    const newMessages = this.messagesToCompare$.getValue();
    if (messages.length >= 0 && newMessages.length > 0) {
      this.pushNewMessagesToMessages(messages, newMessages);
    } else {
    }
  };

  private pushNewMessagesToMessages(messages: TextMessage[], newMessages: TextMessage[]): void {
    const lastNewMessages: Array<TextMessage> = [];
    newMessages.forEach((message) => {
      if (!messages.some((m) => m.id === message.id)) {
        lastNewMessages.push(message);
      }
    });
    if (lastNewMessages.length > 0) {
      this.messagesToDisplay$.next(messages.concat(lastNewMessages));
      this.totalNumberOfMessages = this.messagesToDisplay$.getValue().length;
    }
  }

  public static transformMessages(ms: any[]) {
    return ms?.map((message) => {
      message.createdByDisplay =
        message.createdByName === 'Mobile Portal Integration User' ? 'Me' : message.createdByName;
      message.createdAtTime = MessageService.getMessageTimestamp(message);
      message.datetimeGroup = MessageService.getMessageDatetimeGroup(message);
      return message;
    });
  }

  private static getMessageTimestamp(message: any) {
    const timestamp = new Date(message.createdDate);
    return timestamp
      .toLocaleString()
      .replace(/(.*)\D\d+/, '$1')
      ?.split(', ')[1];
  }

  private static getMessageDatetimeGroup(message: any) {
    const timestamp = new Date(message.createdDate);
    let groupBy = 'Unknown';
    const groupByHour = format(timestamp, 'hh');
    if (isToday(timestamp)) {
      groupBy = 'Today';
    } else if (isYesterday(timestamp)) {
      groupBy = 'Yesterday';
    } else {
      groupBy = format(timestamp, 'MM/dd/yyyy');
    }
    return { groupBy, groupByHour };
  }
  

}





export interface TextMessage {
  id: string;
  source: string;
  body: string;
  createdDate: string;
  createdByRole: string;
  messageSource: string; // "Client Portal" when POSTing
  createdByName: string;
  createdByDisplay?: string;
  createdAtTime?: string;
  datetimeGroup?: {
    groupBy: string;
    groupByHour: string;
  };
}

export interface SendTextMessage {
  matterId: string;
  body: string;
  messageSource: string;
}

export interface PostResponse {
  id: string;
  action: string;
}
