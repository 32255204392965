import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { LocalNotifications, PendingLocalNotificationSchema } from '@capacitor/local-notifications';
import { BehaviorSubject, lastValueFrom, map, share, switchMap, tap } from 'rxjs';
import { CaseMatter } from 'src/app/services/case.service';
import { NotificationsCenterService } from 'src/app/services/notifications-center.service';
import { NotificationReminder } from './pain-level-reminder.service';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';

export const { portalEdition } = environment;

interface PainLevelReminderType {
  wednesday: NotificationReminder
}

@Injectable({
  providedIn: 'root',
})
export class PainLevelReminderStorage {
  private reminderType = `painLevel_${ portalEdition }`;
  private hasStorageBeenInit: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private painLevelReminders = <PainLevelReminderType>{
    wednesday: null,
  };

  constructor(
    private storage: Storage,
    private notificationsCenterService: NotificationsCenterService,
  ) {
    this.initStorage().catch();
  }

  public getRecurringWednesdayPainReminder(matter: CaseMatter): Promise<PainLevelReminderType> {
    return this.storage.get(`${ this.reminderType }_${ matter.id }`);
  }

  public async setRecurringWednesdayPainReminder(matter: CaseMatter, reminder: NotificationReminder) {
    this.painLevelReminders.wednesday = reminder;
    return this.storage.set(`${ this.reminderType }_${ matter.id }`, this.painLevelReminders);
  }

  public async clearPainLevelReminders(matter: CaseMatter) {
    await this.initStorage();
    const pendingReminders = await LocalNotifications.getPending();

    pendingReminders.notifications.forEach((notification: PendingLocalNotificationSchema) => {
      if (notification.extra.type === 'painLevel' && matter.id === notification.extra.matterId) {
        LocalNotifications.cancel({
          notifications: [{ id: notification.id }],
        }).catch(console.error);

        lastValueFrom(this.notificationsCenterService.patchNotifications([
          {
            id: notification.extra.pushNotificationId,
            status: 'Cancelled',
            scheduleDate: notification.extra.scheduleDate,
          },
        ])).catch(console.error);

        this.storage.remove(`${ this.reminderType }_${ matter.id }`).catch(console.error);
      }
    });
  }

  private initStorage = (): Promise<void> => lastValueFrom(this.hasStorageBeenInit.pipe(
    filter(hasInit => !hasInit),
    switchMap(() => this.storage.create()),
    map(() => {
      this.hasStorageBeenInit.next(true);
    }),
    share(),
    tap(() => {
      LocalNotifications.getPending()
        .then(pending => {
          console.log(`***** pending after pain level initStorage ******=*=*=*=*=*=>> \r\n`, pending);
        });
    })
  ));
}
