import { Injectable } from '@angular/core';
import { PluginListenerHandle } from "@capacitor/core";
import { Network, ConnectionStatus } from '@capacitor/network';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, filter, skip, distinctUntilChanged } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private networkListener: Promise<PluginListenerHandle>;
  private statusChangedSubject$ = new BehaviorSubject<ConnectionStatus|null>(null);
  public statusChanged$ = this.statusChangedSubject$.asObservable().pipe(
    skip(1),
    distinctUntilChanged(
      (prev:ConnectionStatus, curr:ConnectionStatus) => prev.connected===curr.connected
    ),
  );

  public connected$: Observable<boolean> = this.statusChanged$.pipe(
    skip(1),
    // distinctUntilChanged( (prev: ConnectionStatus, current: ConnectionStatus) => prev.connected==current.connected ),
    filter( (status: ConnectionStatus) => !!status.connected ),
    map( (status: ConnectionStatus) => status.connected ),
  )

  public disconnected$: Observable<boolean> = this.statusChanged$.pipe(
    filter( (status: ConnectionStatus) => !status.connected ),
    map( (status: ConnectionStatus) => !status.connected ),
  )

  constructor() {
    this.init();
  }

  async init() {
    this.addNetworkListener();

    const status:ConnectionStatus = await this.getStatus();
    console.log("Network init() status", status);
    this.sendStatusChangeMsg(status);
  }
  
  // public getStatusChangeMsg(): Observable<ConnectionStatus> {
  //   return this.status.asObservable();
  // }

  private sendStatusChangeMsg(status: ConnectionStatus): void {
    this.statusChangedSubject$.next(status);
  }


  async getStatus(): Promise<ConnectionStatus> {
    const status: ConnectionStatus = await Network.getStatus();
    console.log('Network getStatus():', status);
    return status;
  }


  public addNetworkListener() {
    this.networkListener = Network.addListener("networkStatusChange", (status: ConnectionStatus) => {
      console.log("Network status changed", status);
      this.sendStatusChangeMsg(status);
    });
  }

  public async removeNetworkListener() {
    (await this.networkListener).remove();
  }
}
