<ion-header class="ion-no-border upgrade-header">
  <ion-toolbar mode="ios" class="header-toolbar">
    <ion-title class="header-title">
      <img src="assets/welcome/white-on-blue-background-logo.svg" alt="M&M"/>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content 
  *ngIf="(upgradeRequired$ | async) as upgradeRequired"
  [fullscreen]="true"
  class="upgrade-content">
  <ion-row *ngIf="upgradeRequired.required && !upgradeRequired.outageEnabled">
    <div class="uprgade-icon-box-light">
      <fa-icon class="icon" [icon]="faSparkles" size="2x"></fa-icon>
    </div>
    <p class="upgrade-heading">{{ upgradeRequired.heading }}</p>
    <p class="upgrade-body">{{ upgradeRequired.body }}</p>
    <ion-button
      href="{{ upgradeRequired.url }}"
      target="_blank"
      color="primary"
      expand="full"
      class="upgrade-button">
      {{ upgradeRequired.button }}
    </ion-button>
  </ion-row>
  <ion-row *ngIf="upgradeRequired.outageEnabled">
    <div class="uprgade-icon-box-light">
      <fa-icon class="icon" [icon]="faScrewdriverWrench" size="2x"></fa-icon>
    </div>
    <p class="upgrade-body">Our Client Portal is undergoing maintenance. If you need immediate assistance, please call <a href="tel:888-670-2630">888-670-2630</a>.<br/>Thank you for your patience!</p>
  </ion-row>
</ion-content>
