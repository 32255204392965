import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalController } from '@ionic/angular';
import { Observable, filter, map, switchMap, timer, tap } from 'rxjs';

import { format, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { AuthService, AuthState, JWT } from './auth.service';
import { environment } from 'src/environments/environment';
import { ScheduledMaintenanceComponent } from '../components/scheduled-maintenance/scheduled-maintenance.component';


interface ScheduledMaintenance {
  start_time: string,
  end_time: string
}

@Injectable({
  providedIn: 'root'
})
export class ScheduledMaintenanceService {
  constructor(
    private http: HttpClient,
    private authSvc: AuthService,
    private modalController: ModalController,
  ) { }

  public activeScheduledMaintenance$: Observable<ScheduledMaintenance> = this.authSvc.currentState$?.pipe(
    filter((authState: AuthState) => !!authState.jwt),
    map((authState: AuthState) => <JWT>authState.jwt),
    switchMap(() => this.http.get<ScheduledMaintenance>(`${environment.api.edwinBaseUrl}/scheduled_maintenance/?active=1`))
  );

  private presentModal(startTime: String, endTime: String): void {
    this.modalController.create({
      component: ScheduledMaintenanceComponent,
      componentProps: {
        startTime,
        endTime,
      },
      cssClass: 'full-screen' // this class is defined in global.scss
    }).then(modal => modal.present());
  }

  public handleScheduledMaintenance(): void {
    this.activeScheduledMaintenance$.subscribe((activeMaintenance: ScheduledMaintenance) => {
      if (!activeMaintenance) {
        return
      }

      const currentTime = Date.now()
      const startTimeUTC = zonedTimeToUtc(activeMaintenance.start_time, 'UTC').toISOString();
      const endTimeWithUTC = zonedTimeToUtc(activeMaintenance.end_time, 'UTC').toISOString();
      const startTime = Date.parse(startTimeUTC)
      const endTime = Date.parse(endTimeWithUTC)
      const formattedStartTime = format(parseISO(startTimeUTC), 'MM-dd-yyyy HH:mm aaa')
      const formattedEndTime = format(parseISO(endTimeWithUTC), 'MM-dd-yyyy HH:mm aaa')

      // currently in a maintenance timeframe
      if (currentTime > startTime && currentTime < endTime) {
        return this.presentModal(formattedStartTime, formattedEndTime)
      }

      // there is a maintenance timeframe in the future
      if (currentTime < startTime) {
        const triggerTime = startTime - currentTime
        timer(triggerTime).subscribe(() => {
          return this.presentModal(formattedStartTime, formattedEndTime)
        })
      }
    })
  }
}