/**
 * 32x32 favicon for FCM Notifications:
 *    https://www.forthepeople.com/themes/custom/ftp/images/favicons/Favicon_32x32.png
 */
import { Injectable, NgZone } from '@angular/core';
import { FCM } from '@capacitor-community/fcm';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed as LocalActionPerformed,
  LocalNotifications,
  LocalNotificationSchema,
} from '@capacitor/local-notifications';
import {
  ActionPerformed,
  PermissionStatus,
  PushNotifications,
  PushNotificationSchema,
} from '@capacitor/push-notifications';
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  map,
  Observable,
  pairwise,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationSettings, NotificationSettingsService } from 'src/app/services/notification-settings.service';
import {
  INotification,
  IPushNotificationService,
  Notification,
} from 'src/app/services/notification.service.interface';
import { NotificationFactory } from 'src/app/services/notification/notification-factory';
import { isWeb } from 'src/app/services/util';
import { environment } from 'src/environments/environment';

const { portalEdition } = environment;

const removeFromArray = (arr: string[], topic: string) => arr.indexOf(topic) == -1 ?
  arr :
  arr.slice(0, arr.indexOf(topic)).concat(arr.slice(arr.indexOf(topic) + 1));

const MESSAGE_TOPIC = 'message';
const CASE_STATUS_TOPIC = 'caseStatus';
const ONBOARDING_REMINDER_TOPIC = 'onboardingReminder';
const TREATMENT_SCHEDULED_TOPIC = 'treatmentScheduled';
const CALL_RESCHEDULED_TOPIC = 'callRescheduled';
const TASKS_TOPIC = 'tasks';

const EDITION = portalEdition;

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements IPushNotificationService {

  private notificationsSubject$: BehaviorSubject<Notification | null> = new BehaviorSubject(null);
  public notifications$: Observable<INotification> = this.notificationsSubject$.pipe(
    filter((notification: Notification | null) => notification !== null),
    map((notification: Notification) => NotificationFactory.makeNotification(notification)),
    shareReplay(1),
    tap((notification: INotification) => console.log('NotificationService.notifications$ INotification:', notification, 'matterId:', notification.data.matterId, JSON.stringify(notification))),
  );

  private fcmRegistered$ = new Subject<boolean>();
  private settingStates$: Observable<NotificationSettings> = this.settingsSvc.toggleStates$;

  public subscriptionSettings$: Observable<[NotificationSettings, NotificationSettings]> = this.fcmRegistered$.pipe(
    switchMap(() => this.settingStates$),
    startWith({
      messages: false, caseStatus: false, painLevel: false,
      days: [false, false, false, false, false, false, false],
    }),
    pairwise(),
    tap(([previousSettings, currentSettings]: [NotificationSettings, NotificationSettings]) => console.log('PushNotifications - [previousSettings, settings]:', previousSettings, currentSettings)),
  );

  private subscribedTopics: string[] = [];
  private accountID: string | null = null;

  constructor(
    private authSvc: AuthService,
    private settingsSvc: NotificationSettingsService,
    private zone: NgZone,
  ) { }

  init() {
    if (!isWeb()) {
      return Promise.all([
        // DO NOT include registerPush in here:
        //   that will be done manually at the right time with an accountID
        this.addPushNotificationListeners(),
        this.addLocalNotificationListeners(),
      ]);
    }



  }

  public registrationComplete: Promise<void> = new Promise((resolve) => {
    if (!isWeb())PushNotifications.addListener('registration', () => {
      resolve();
    });
  });

  async checkPermissions(): Promise<PermissionStatus> {
    if (Capacitor.getPlatform() !== 'web') {
      return PushNotifications.checkPermissions();
    }
    return Promise.reject({ error: 'Not supported in Web Mode' });
  }

  async requestPermissions(): Promise<PermissionStatus> {
    if (Capacitor.getPlatform() !== 'web') {
      return PushNotifications.requestPermissions();
    }
    return Promise.reject({ error: 'Not supported in Web Mode' });
  }

  async registerPush() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    if (!isWeb()) {
      return this.requestPermissions().then(
        async (result) => (result.receive === 'granted') ?
          // Register with Apple / Google to receive push via APNS/FCM
          await PushNotifications.register()
          :
          // Show some error
          Promise.reject({
            error: 'Requesting Permissions failed:',
            result,
          }),
      ).then(
        async () => {
          this.accountID = (await firstValueFrom(this.authSvc.currentState$)).jwt.account_id;
        },
      );
    }
  }

  /**
   * General subscribe/unsubscribe methods
   */
  public async subscribeToTopic(topic: string) {
    this.subscribedTopics.push(topic);
    return FCM.subscribeTo({ topic });
  }

  public async unsubscribeFromTopic(topic: string) {
    removeFromArray(this.subscribedTopics, topic);
    return FCM.unsubscribeFrom({ topic });
  }

  async unsubscribeFromAll() {
    return Promise.all(
      this.subscribedTopics.map((topic: string) => this.unsubscribeFromTopic(topic)),
    );
  }

  /**
   * Call this when PushNotifications is registered, then subscribeTo with FCM will be ready
   */
  initializeSubscriptions() {
    this.fcmRegistered$.next(true);
  }

  /**
   * this will be called from an outside class (e.g., AppComponent) where ever,
   * it subscribes to subscriptionSettings$.  This is preferable to subscribing
   * to an Observable inside the service.
   */
  subscribeAndUnsubscribeToFcmTopicsBasedOnSettings(previousSettings: NotificationSettings, currentSettings: NotificationSettings) {
    if (previousSettings.messages !== currentSettings.messages) {
      currentSettings.messages ? this.subscribeToMessages() : this.unsubscribeFromMessages();
    }

    if (previousSettings.caseStatus !== currentSettings.caseStatus) {
      currentSettings.caseStatus ? this.subscribeToCaseStatus() : this.unsubscribeFromCaseStatus();
    }

    if (previousSettings.tasks !== currentSettings.tasks) {
      currentSettings.tasks ? this.subscribeToTasks() : this.unsubscribeFromTasks();
    }
  }

  public emitNotification(notificationWrapper: ActionPerformed | LocalActionPerformed | {
    notification: PushNotificationSchema | LocalNotificationSchema
  }, action: boolean = false): void {
    const data = notificationWrapper.notification['data'] || notificationWrapper.notification['extra'];
    if (data.type) {
      this.notificationsSubject$.next(<Notification>{
        id: data.pushNotificationId,
        action,
        data,
      });
    }
  }

  /**
   * Listener methods
   */
  private async addPushNotificationListeners() {
    await PushNotifications.addListener('registration', (token) => {
      console.info('PushNotifications Registration token:', JSON.stringify(token));
    });

    await PushNotifications.addListener('registrationError', (err: any) => {
      console.log('PushNotifications Registration Error:', err.error, JSON.stringify(err));
    });

    await PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      this.zone.run(() => {
        this.emitNotification({ notification });
      });
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', (notificationAction: ActionPerformed) => {
      this.zone.run(() => {
        this.emitNotification(notificationAction, true);
      });
    });
  }

  private async addLocalNotificationListeners() {
    await LocalNotifications.addListener('localNotificationReceived', (notification: LocalNotificationSchema) => {
      /**
       * Same as below, but 'action' param is set to false, since this is the listener
       * for when the scheduled Notification takes place, and the app detects it since
       * it is in the foreground - meaning perhaps some data update will need to happen,
       * but mainly the AppComponent will issue the InAppToast
       */
      this.emitNotification({ notification }); //NOTE: no 'action' param - it is FALSE
    });

    await LocalNotifications.addListener('localNotificationActionPerformed', (notificationAction: LocalActionPerformed) => {
      this.emitNotification(notificationAction, true);
    });
  }

  /**
   * Custom / Specific topic subscribe/unsubscribe methods
   */
  subscribeToMessages() {
    const topic = `${ EDITION }_${ MESSAGE_TOPIC }_${ this.accountID }`;
    return this.subscribeToTopic(topic);
  }

  subscribeToCaseStatus() {
    const topic = `${ EDITION }_${ CASE_STATUS_TOPIC }_${ this.accountID }`;
    return this.subscribeToTopic(topic);
  }

  subscribeToOnboardingReminder() {
    const topic = `${ EDITION }_${ ONBOARDING_REMINDER_TOPIC }_${ this.accountID }`;
    return this.subscribeToTopic(topic);
  }

  subscribeToTreatmentScheduled() {
    const topic = `${ EDITION }_${ TREATMENT_SCHEDULED_TOPIC }_${ this.accountID }`;
    return this.subscribeToTopic(topic);
  }

  async subscribeToCallRescheduled() {
    const topic = `${ EDITION }_${ CALL_RESCHEDULED_TOPIC }_${ this.accountID }`;
    return this.subscribeToTopic(topic);
  }

  async subscribeToTasks() {
    const topic = `${ EDITION }_${ TASKS_TOPIC }_${ this.accountID }`;
    return this.subscribeToTopic(topic);
  }

  unsubscribeFromMessages() {
    const topic = `${ EDITION }_${ MESSAGE_TOPIC }_${ this.accountID }`;
    return this.unsubscribeFromTopic(topic);
  }

  unsubscribeFromCaseStatus() {
    const topic = `${ EDITION }_${ CASE_STATUS_TOPIC }_${ this.accountID }`;
    return this.unsubscribeFromTopic(topic);
  }

  unsubscribeFromTasks() {
    const topic = `${ EDITION }_${ TASKS_TOPIC }_${ this.accountID }`;
    return this.unsubscribeFromTopic(topic);
  }
}
