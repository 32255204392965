import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { authGuardCanActivate } from './guards/auth.guard';
import { bypassAuthenticationGuardCanActivate } from './guards/bypass-authentication.guard';

const routes: Routes = [
  //   //This route is just here to trigger the BypassAuthencationGuard when Auth0 returns to the web-app
  //   //(when returning to the web app the route isn't changing so we this forces a navgation change for the Guard below)
  // {
  //   path: 'init-post-login-flow',
  //   loadChildren: () => import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule),
  //   canActivate: [bypassAuthenticationGuardCanActivate()]
  // },
  {
    path: 'splash-screen',
    loadChildren: () => import('./pages/splash-screen/splash-screen.module').then((m) => m.SplashScreenPageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('src/app/pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [authGuardCanActivate()],
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then((m) => m.OnboardingPageModule),
    canActivateChild: [authGuardCanActivate()],
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivateChild: [authGuardCanActivate()],
  },
  {
    // leave this in - still used for "view all" button on case page
    path: 'journal',
    loadChildren: () => import('./pages/journal/journal.module').then((m) => m.JournalPageModule),
    canActivateChild: [authGuardCanActivate()],
  },
  {
    path: 'documents',
    loadChildren: () => import('./pages/documents/documents.module').then((m) => m.DocumentsPageModule),
    canActivateChild: [authGuardCanActivate()],
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsPageModule),
    canActivateChild: [authGuardCanActivate()],
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then((m) => m.ContactPageModule),
    canActivateChild: [authGuardCanActivate()],
  },
  {
    path: 'notifications',
    canActivate: [authGuardCanActivate()],
    loadChildren: () =>
      import('./pages/notifications-center/notifications-center.module').then((m) => m.NotificationsPageModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then((m) => m.FaqPageModule),
  },
  {
    path: 'legal-team-member',
    loadChildren: () =>
      import('./pages/legal-team-member/legal-team-member.module').then((m) => m.LegalTeamMemberPageModule),
  },
  {
    path: 'survey/:surveyName',
    loadChildren: () => import('./pages/survey/survey.module').then((m) => m.SurveyPageModule),
    canActivate: [authGuardCanActivate()],
  },
  {
    //DEPRECATED
    path: 'survey/:surveyName/:intakeId/:matterId',
    redirectTo: '/survey/:surveyName',
  },
  {
    //DEPRECATED
    path: 'survey/:surveyName/:intakeId',
    redirectTo: '/survey/:surveyName',
  },
  {
    path: 'image-cropper',
    loadChildren: () => import('./pages/image-cropper/image-cropper.module').then((m) => m.ImageCropperPageModule),
  },
  {
    path: 'case-activity-digest',
    loadChildren: () =>
      import('./pages/case-activity-digest/case-activity-digest.module').then((m) => m.CaseActivityDigestPageModule),
    canActivate: [authGuardCanActivate()],
  },
  {
    path: 'tasks',
    loadChildren: () => import('./pages/tasks/tasks.module').then((m) => m.TasksPageModule),
    canActivate: [authGuardCanActivate()],
  },
  {
    path: 'measure-one',
    loadChildren: () => import('./pages/measure-one/measure-one.module').then((m) => m.MeasureOnePageModule),
  },
  {
    //ALL IN get-started.routing.module.ts:  login, logout, verify, verified, no-case, finish-registration
    path: '',
    // redirectTo: (!environment.sandbox) ? '/' : 'sandbox',
    loadChildren: () => import('./pages/get-started/get-started.module').then((m) => m.GetStartedPageModule),
  },
  {
    path: 'self-guided-signup',
    loadChildren: () =>
      import('./pages/self-guided-signup/self-guided-signup.module').then((m) => m.SelfGuidedSignupPageModule),
  },
  {
    path: 'interstitial-landing',
    loadChildren: () =>
      import('./pages/interstitial-landing/interstitial-landing.module').then((m) => m.InterstitialLandingPageModule),
  },
  {
    path: 'upcoming-calls',
    loadChildren: () => import('./pages/upcoming-calls/upcoming-calls.module').then((m) => m.UpcomingCallsPageModule),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
