import { createAnimation } from '@ionic/angular';

export const quickPageAnimation = (_: HTMLElement, opts: any) => {
  const isSurvey = opts?.enteringEl?.localName?.match(/survey/g) || opts?.leavingEl?.localName?.match(/survey/g);
  const rootTransition = createAnimation()
    .easing('ease-in-out')
    .duration(opts.duration || 300);

  const enterTransition = createAnimation().addElement(opts.enteringEl);
  const exitTransition = createAnimation().addElement(opts.leavingEl);

  if (isSurvey) { // if entering a survy page, animate up and down
    if (opts.direction === 'forward') {
      // forward (screens move from bottom to top)
      enterTransition.fromTo('opacity', '1', '1').fromTo('transform', 'translateY(100%)', 'translateY(0%)');
      exitTransition.fromTo('opacity', '1', '1').fromTo('z-index', '1', '0');
    } else {
      // back (screens move from top to bottom)
      enterTransition.fromTo('opacity', '1', '1').fromTo('z-index', '0', '1');
      exitTransition.fromTo('opacity', '1', '1').fromTo('transform', 'translateY(0%)', 'translateY(100%)');
    }
  } else { // not a survey survy page, animate left and right
    if (opts.direction === 'forward') {
      // forward (screens move from right to left)
      enterTransition.fromTo('opacity', '0', '1').fromTo('transform', 'translateX(100%)', 'translateX(0%)');
      exitTransition.fromTo('opacity', '1', '0').fromTo('transform', 'translateX(0%)', 'translateX(-100%)');
    } else {
      // back (screens move from left to right)
      enterTransition.fromTo('opacity', '0', '1').fromTo('transform', 'translateX(-100%)', 'translateX(0%)');
      exitTransition.fromTo('opacity', '1', '0').fromTo('transform', 'translateX(0%)', 'translateX(100%)');
    }
  };
  rootTransition.addAnimation([enterTransition, exitTransition]);
  return rootTransition;
}

// If you don't assign a direction, the default is forward.

// To use forward and backward page transition directions from HTML:
// <ion-button routerLink="/route" routerDirection="forward">Forward</ion-button>
// <ion-button routerLink="/route" routerDirection="back">Back</ion-button>

// To use forward and backward page transition directions programattically:
// import { NavController } from '@ionic/angular';
// public navCtrl: NavController,
// this.navCtrl.navigateForward('/route');
// this.navCtrl.navigateBack('/route');
// this.navCtrl.navigateRoot('/route');

// https://www.joshmorony.com/using-angular-routing-with-ionic-4/
