import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, from, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClientCase, CaseMatter, CaseService } from './case.service';

const { api: { baseUrl, pushNotificationsUri } } = environment;

@Injectable({
  providedIn: 'root',
})
export class NotificationsCenterService {
  private readonly notificationUrl: string = `${ baseUrl }/${ pushNotificationsUri }`;
  private hasUnreadUpdate: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hasUnread$: Observable<boolean> = this.hasUnreadUpdate;
  private currentMatterIdUpdate: BehaviorSubject<string> = new BehaviorSubject(null);
  public currentMatterId$: Observable<string> = this.currentMatterIdUpdate;
  private mattersNotificationsUpdate: BehaviorSubject<{ [key: string]: GetNotificationUnparsed[] }> = new BehaviorSubject(null);
  public mattersNotifications$ = this.mattersNotificationsUpdate;
  private cachedNotificationsByMatterId: { [key: string]: { [key: string]: GetNotificationUnparsed[] } } = {};

  constructor(
    private http: HttpClient,
    private caseService: CaseService,
  ) { }

  public postNotification(currentCase: CaseMatter, notificationBody) {
    return this.http.post<any>(`${ this.notificationUrl }/${ currentCase.id }`, notificationBody)
      .pipe(
        take(1),
        tap(() => this.refreshNotifications()),
      );
  }

  public getNotificationsByMatterId(matterId: string) {
    if (!matterId) {
      return from([]);
    }

    return this.http.get<GetNotificationUnparsed[]>(`${ this.notificationUrl }/${ matterId }?sort=-createdDate&limit=1000`)
      .pipe(
        map(this.processMatterNotifications.bind(this)),
        tap((matterNotifications: { [key:string]: GetNotificationUnparsed[]}) => {
          this.cachedNotificationsByMatterId[matterId] = matterNotifications;
        }),
      );
  }

  public patchNotifications(patchData) {
    if (!patchData || !patchData?.length) {
      return from([]);
    }
    const unique = [
      ...new Map(patchData.map((item) => [item?.data?.pushNotificationId || item?.id, item])).values(),
    ];

    return this.http.patch<any[]>(`${ this.notificationUrl }/`, unique).pipe(
      take(1),
    );
  }

  public async init() {
    const cases: ClientCase[] = (await firstValueFrom(this.caseService.cases$));
    const currentMatterId = (await firstValueFrom(this.caseService.selectedCase$))?.matter?.id;
    if (currentMatterId) {
      this.setCurrentMatterId(currentMatterId);
    } else {
      this.setCurrentMatterId(cases[0]?.matter.id);
    }

    const proms = [];
    cases.filter((clientCase: ClientCase) => clientCase?.matter?.id !== currentMatterId)
      .forEach((clientCase: ClientCase) => {
      proms.push(firstValueFrom(this.getNotificationsByMatterId(clientCase?.matter?.id)))
    });

    await Promise.all(proms);
  }

  private processMatterNotifications(matterNotifications: GetNotificationUnparsed[]) {
    return matterNotifications.reduce(
      this.designateMatterNotifications.bind(this),
      { all: [], unread: [], scheduled: [], sent: [], painLevel: [], messages: [], caseStatus: [], treatmentScheduled: [], callRescheduled: [] },
    );
  }

  private designateMatterNotifications(matterNotifications, notification) {
    // console.error("notification",  notification);
    const now = new Date();
    const routeMatches = /message_|caseStatus_|painLevel_|treatmentScheduled_|callRescheduled_/;
    const matches = routeMatches.exec(notification.topic);

    if (matches) {
      switch (matches[0]) {
        case 'message_':
          notification.route = '/tabs/messages';
          matterNotifications.messages.push(notification);
          break;
        case 'caseStatus_':
          notification.route = '/tabs/case';
          matterNotifications.caseStatus.push(notification);
          break;
        case 'painLevel_':
          notification.route = '/journal/pain-level/add';
          matterNotifications.painLevel.push(notification);
          break;
        case '"treatmentScheduled_':
          notification.route = '/tabs/health';
          matterNotifications.treatmentScheduled.push(notification);
          break;
        case '"callRescheduled_':
          notification.route = '/tabs/case';
          matterNotifications.callRescheduled.push(notification);
          break;

      }
    }

    if (notification.status.toLowerCase() === 'sent' ||
      (notification.status.toLowerCase() === 'read') ||
      (new Date(notification.scheduleDate) <= now &&
      notification.status.toLowerCase() !== 'cancelled' &&
      (matterNotifications.all.findIndex(n => n.id === notification.id) === -1))
    ) {
      matterNotifications.all.push(notification);
    }

    if (notification.status.toLowerCase() === 'sent' ||
      (notification.status.toLowerCase() !== 'read' &&
      notification.status.toLowerCase() !== 'cancelled' &&
      new Date(notification.scheduleDate) <= now &&
      (matterNotifications.unread.findIndex(n => n.id === notification.id) === -1))
    ) {
      matterNotifications.unread.push(notification);
    }

    if (
      notification.status.toLowerCase() === 'scheduled' &&
      (matterNotifications.scheduled.findIndex(n => n.id === notification.id) === -1)
    ) {
      matterNotifications.scheduled.push(notification);
    }

    if (
      notification.status.toLowerCase() === 'sent' &&
      (matterNotifications.sent.findIndex(n => n.id === notification.id) === -1)
    ) {
      matterNotifications.sent.push(notification);
    }

    return matterNotifications;
  }

  public setCurrentMatterId(matterId) {
    if (!matterId) { return; }
    this.currentMatterIdUpdate.next(matterId);
    this.refreshNotifications();
  }

  public setCurrentNotifications(notifications: { [key: string]: GetNotificationUnparsed[] }) {
    if (!notifications) { return; }
    this.mattersNotificationsUpdate.next(notifications);
    this.setHasUnread(!!notifications?.unread?.length);
  }

  public setHasUnread(hasUnread: boolean) {
    this.hasUnreadUpdate.next(hasUnread);
  }

  public refreshNotifications() {
    this.setCurrentNotifications({});
    return this.currentMatterId$.pipe(
        take(1),
        // tap(console.trace),
        switchMap((matterId) => this.getNotificationsByMatterId(matterId)),
        // tap(console.trace),
      )
      .subscribe((notifications: { [key: string]: GetNotificationUnparsed[] }) => this.setCurrentNotifications(notifications));
  }

  public getCachedNotificationsByMatterId(matterId: string) {
    return this.cachedNotificationsByMatterId[matterId];
  }

  public markNotificationSent(notification) {
    const transformed = this.transformToSent(notification);
    return this.patchNotifications([transformed]).pipe(
      map(this.refreshNotifications.bind(this)),
    );
  }

  public markNotificationRead(notification) {
    const transformed = this.transformToReadOrCancelled(notification);
    return this.patchNotifications([transformed]).pipe(
      take(1),
      map(this.refreshNotifications.bind(this)),
    ).subscribe();
  }

  public markPainLevelNotificationsRead() {
    return this.mattersNotifications$.pipe(
      take(1),
      switchMap((notifications) => {
        if (!notifications) return of(null);
        const patchData = notifications.painLevel
          ?.filter((notification) => notification?.status?.toLowerCase() !== 'read' &&
            notification?.status?.toLowerCase() !== 'cancelled')
          .map(this.transformToRead.bind(this));

        return this.patchNotifications(patchData).pipe(
          map(this.refreshNotifications.bind(this)),
        );
      }),
    ).subscribe();
  }

  public markMessageNotificationsRead() {
    return this.mattersNotifications$.pipe(
      take(1),
      switchMap((notifications) => {
        if (!notifications) return of(null);
        const patchData = notifications.messages
          ?.filter((notification) => notification?.status?.toLowerCase() !== 'read' &&
            notification?.status?.toLowerCase() !== 'cancelled')
          .map(this.transformToRead.bind(this));

        return this.patchNotifications(patchData).pipe(
          map(this.refreshNotifications.bind(this)),
        );
      }),
    ).subscribe();
  }

  public markCaseStatusNotificationsRead() {
    return this.mattersNotifications$.pipe(
      take(1),
      switchMap((notifications) => {
        if (!notifications) return of(null);
        const patchData = notifications.caseStatus
          ?.filter((notification) => notification?.status?.toLowerCase() !== 'read' &&
            notification?.status?.toLowerCase() !== 'cancelled')
          .map(this.transformToRead.bind(this));

        return this.patchNotifications(patchData).pipe(
          map(this.refreshNotifications.bind(this)),
        );
      }),
    ).subscribe();
  }

  public markTreatmentNotificationsRead() {
    return this.mattersNotifications$.pipe(
      take(1),
      switchMap((notifications) => {
        if (!notifications) return of(null);
        const patchData = notifications.treatments
          ?.filter((notification) => notification?.status?.toLowerCase() !== 'read' &&
            notification?.status?.toLowerCase() !== 'cancelled')
          .map(this.transformToRead.bind(this));

        return this.patchNotifications(patchData).pipe(
          map(this.refreshNotifications.bind(this)),
        );
      }),
    ).subscribe();
  }

  private transformToReadOrCancelled(notification) {
    if (!notification) return of(null);
    const todayAtNoonEastern = new Date();

    return new Date(notification.scheduleDate) < todayAtNoonEastern ?
      this.transformToRead(notification) :
      this.transformToCancelled(notification);
  }

  public transformToRead(notification) {
    return {
      id: notification?.data?.pushNotificationId || notification?.id,
      status: 'Read',
    };
  }

  public transformToSent(notification) {
    return {
      id: notification?.data?.pushNotificationId || notification?.id,
      status: 'Sent',
    };
  }

  public transformToCancelled(notification) {
    return {
      id: notification?.data?.pushNotificationId || notification?.id,
      status: 'Cancelled',
    };
  }
}

export interface GetNotificationUnparsed {
  source: string;
  inputSource: string;
  data: string;
  topic: string;
  status: string;
  title: string;
  matterId: string;
  accountId: string;
  body: string;
  id: string;
  createdDate: string;
  scheduleDate: string;
  route?: string;
}
