import { Injectable } from '@angular/core';

import { ScanbotSDK, ScanbotSdkConfiguration, DocumentScannerConfiguration, Page } from 'capacitor-plugin-scanbot-sdk';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScanbotService {

  constructor() {}

  async initScanbotSdk() {
    const config: ScanbotSdkConfiguration = {
        loggingEnabled: true,
        licenseKey: environment.scanbot.nativeLicense,
    };
    try {
        await ScanbotSDK.initializeSDK(config);
    } catch (e) {
        console.error(`Error initializing Scanbot SDK: ${e}`);
    }
  }

  async checkLicense(): Promise<boolean> {
    const result = await ScanbotSDK.getLicenseInfo();
    if (result.isLicenseValid) {
      return true;
    } else {
      console.error('Scanbot SDK license is not valid!');
      return false;
    }
  }

  async scanDocument() {
    if (!await this.checkLicense()) {
      return;
    }

    const configuration: DocumentScannerConfiguration = {
      acceptedAngleScore: 60,
      acceptedSizeScore: 60,
      autoSnappingSensitivity: 0.66, // set to default, so technically not needed but keeping so we don't forget we can adjust
      cameraPreviewMode: 'FIT_IN',
      orientationLockMode: 'PORTRAIT',
      pageCounterButtonTitle: '%d Page(s)',
      multiPageButtonHidden: true,
      multiPageEnabled: false,
      ignoreBadAspectRatio: true,
      topBarBackgroundColor: '#132A4E',
      bottomBarBackgroundColor: '#132A4E',
    };
    
    const result = await ScanbotSDK.startDocumentScanner(configuration);
    
    if (result.status === 'CANCELED') {
      return;
    }
    
    return result.pages;
  }
}
