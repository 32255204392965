import { Injectable } from '@angular/core';
import { ToastController, ToastOptions, ToastButton } from '@ionic/angular';
import { AlertController, AlertOptions, AlertButton } from '@ionic/angular';
import { INotification } from 'src/app/services/notification.service.interface';
import { NotificationService } from './notification.service';
import { NotificationFactory } from './notification/notification-factory';
import { ToastService } from './toast.service';


export const TOAST_DURATION = 10000;

export interface IInAppNotificationService {
  show: (notification: INotification, options?: any) => void;
}

@Injectable({
  providedIn: 'root'
})
export class InAppNotificationToastService implements IInAppNotificationService {

  constructor(
    private toastCtrl:  ToastController,
    private toastService:  ToastService,
    private notificationService:  NotificationService,
  ) { }

  public show(notification: INotification, options: ToastOptions = {}) {
    const destination: string = NotificationFactory.destinationLabelMapping(notification);

    // this.toastCtrl
    //   .create({
    this.toastService.showNoDefaultOptions(<ToastOptions>{
        icon: 'notifications-outline',
        header: notification.data.body,
        duration: TOAST_DURATION,
        position: 'top',
        cssClass: 'treatment-reminder',
        animated: true,
        // color: 'primary',

        buttons: [
         <ToastButton>{
            text: 'Dismiss',
            role: 'cancel',            
            handler: (): Promise<boolean> => {
              console.log('DISMISS handler');
              return Promise.resolve(true);
            },
          },
          <ToastButton>{
            text: destination ? `Go to ${destination}` : 'Acknowledge',
            // icon: 'notifications-outline',
            handler: (): Promise<boolean> => {
              console.log('ACKNOWLEDGE handler');
              this.notificationService.emitNotification({notification}, true);  //TODO:  WHAT TYPE GOES HERE??????
              return Promise.resolve(true);
            },
          },

        ],
      })
      // .then((ctrl: any) => ctrl.present());
  }

}



@Injectable({
  providedIn: 'root'
})
export class InAppNotificationAlertService implements IInAppNotificationService {

  constructor(
    // private toastCtrl:  ToastController,
    private alertCtrl: AlertController,
    private notificationService:  NotificationService,
  ) { }



  public show(notification: INotification, options: AlertOptions = {}) {
    const destination: string = NotificationFactory.destinationLabelMapping(notification);

    this.alertCtrl
      .create(<AlertOptions>{
        header: notification.data.body,
        // subHeader: 'text',
        cssClass: 'in-app-notification-alert',

        buttons: [
         <AlertButton>{
            text: 'Dismiss',
            role: 'cancel',
            cssClass: 'in-app-notification-alert-dismiss',
            // side: 'start',
            handler: (): Promise<boolean> => {
              console.log('DISMISS handler');
              return Promise.resolve(true);
            },
          },
          <AlertButton>{
            text: destination ? `Go to ${destination}` : 'Acknowledge',
            cssClass: 'in-app-notification-alert-goto',
            // side: 'end',
            handler: (): Promise<boolean> => {
              console.log('ACKNOWLEDGE handler');
              this.notificationService.emitNotification({notification}, true);  //TODO:  WHAT TYPE GOES HERE??????
              return Promise.resolve(true);
            },
          },
        ],

      }).then((ctrl: any) => {
        ctrl.present();
        setTimeout(
          ()=>ctrl.dismiss(),
          TOAST_DURATION
        );
      });


  }

}
// <ion-icon name="notifications-outline"></ion-icon>
