import { Injectable } from '@angular/core';
import { concatMap, from, lastValueFrom,  of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { JournalService } from 'src/app/services/journal.service';
import { Matter } from 'src/app/services/matter.service';
import { NotificationSettings } from 'src/app/services/notification-settings.service';
import { environment } from 'src/environments/environment';
import { PainLevelReminderScheduler } from './pain-level-reminder-scheduler';
import { PainLevelReminderStorage } from './pain-level-reminder-storage';

export const { portalEdition } = environment;

@Injectable({
  providedIn: 'root',
})
export class PainLevelReminderService {
  constructor(
    private painLevelReminderScheduler: PainLevelReminderScheduler,
  ) {}

  public init([{ painLevel }, matters, accountId]: [NotificationSettings, Matter[], string]) {
    lastValueFrom(
      from(matters)
      .pipe(
        concatMap(val => of(val).pipe(delay(500))),
        tap(async (matter: Matter) => {
          return this.painLevelReminderScheduler.clearPainLevelReminders(matter);

          //BYPASS iniitialization - the following is being blocked/discontinued:
          const existing = await this.painLevelReminderScheduler.getRecurringWednesdayPainReminder(matter);
          if (!existing && !matter.noTreatmentReason && !matter.litigationNoTreatReason && !!painLevel) {
            this.painLevelReminderScheduler.scheduleRecurringReminder(matter, accountId);
          } else {
            return this.painLevelReminderScheduler.clearPainLevelReminders(matter);
          }
        }),
      )
    ).catch(console.error);
  };
}

export interface PostNotification {
  id?: number;
  accountId: string;
  title: string;
  body: string;
  data: string;
  inputSource: string;
  topic: string;
  scheduleDate: Date;
  status: string;
  matterId: string;
}

export interface NotificationReminder {
  title: string;
  body: string;
  matterId: string;
  pushNotificationId: string;
  localNotificationId: number;
  scheduleDate: Date;
  type: string;
  id: number;
}
