import { INotification, Notification, NotificationData } from '../notification.service.interface';

export abstract class NotificationBase implements INotification {
  id = null;
  action = false;
  route = null;
  data = <NotificationData>{
    id: '',
    title: '',
    body: '',
    matterId: '',
    type: '',
  };

  constructor(args: Notification) {
    Object.assign(this, args);
    this.init();
  }

  abstract init()
}

export class NotificationMessage extends NotificationBase {
  init() {
    this.route = this.action ? '/tabs/messages' : null;
  }
}

export class NotificationCaseStatus extends NotificationBase {
  init() {
    this.route = this.action ? '/tabs/case' : null;
  }
}

export class NotificationPainLevel extends NotificationBase {
  init() {
    this.route = this.action ? '/journal/pain-level/add' : null;
  }
}

export class NotificationTreatment extends NotificationBase {
  init() {
    this.route = this.action ? '/journal/treatment/add' : null;
  }
}

export class NotificationTreatmentScheduled extends NotificationBase {
  init() {
    //TODO: UNDO THIS - THIS IS A HACK!!! Fix in SF so that the notification data decides to route or not.
    // this.route = this.action ? '/tabs/health' : null;
    this.route = '/tabs/health';
  }
}

export class NotificationOnboardingReminder extends NotificationBase {
  init() {
    this.route = this.action ? '/home' : null;
  }
}

export class NotificationCallRescheduled extends NotificationBase {
  init() {
    this.route = '/tabs/case';
  }
}

export class NotificationTasks extends NotificationBase {
  init() {
    this.route = '/tasks';
  }
}

export class NotificationUnknown extends NotificationBase {
  init() {}
}

export enum NotificationType {
  message = 'message',
  caseStatus = 'caseStatus',
  painLevel = 'painLevel',
  treatment = 'treatment',
  treatmentScheduled = 'treatmentScheduled',
  onboardingReminder = 'onboardingReminder',
  callRescheduled = 'callRescheduled',
  tasks = 'tasks',
  unknown = '',
}

export class NotificationFactory {
  static makeNotification(notification: Notification): INotification {
    const dnt: NotificationType = this.decodeNotificationTypes(notification.data.type);

    switch (dnt) {
      case NotificationType.message:
        return new NotificationMessage(notification);

      case NotificationType.caseStatus:
        return new NotificationCaseStatus(notification);

      case NotificationType.painLevel:
        return new NotificationPainLevel(notification);

      case NotificationType.treatment:
        return new NotificationTreatment(notification);

      case NotificationType.treatmentScheduled:
        return new NotificationTreatmentScheduled(notification);

      case NotificationType.onboardingReminder:
        return new NotificationOnboardingReminder(notification);

      case NotificationType.callRescheduled:
        return new NotificationCallRescheduled(notification);

      case NotificationType.tasks:
        return new NotificationTasks(notification);

      case NotificationType.unknown:
      default:
        return new NotificationUnknown(notification);
    }
  }

  static decodeNotificationTypes(type: string): NotificationType {
    switch (type) {
      case 'message':
        return NotificationType.message;
      case 'caseStatus':
        return NotificationType.caseStatus;
      case 'painLevel':
        return NotificationType.painLevel;
      case 'treatment':
        return NotificationType.treatment;
      case 'treatmentscheduled':
        return NotificationType.treatmentScheduled;
      case 'onboardingReminder':
        return NotificationType.onboardingReminder;
      case 'callRescheduled':
        return NotificationType.callRescheduled;
      case 'tasks':
        return NotificationType.tasks;
      default:
        return NotificationType.unknown;
    }
  }

  static destinationLabelMapping(notification: INotification): string | null {
    const type: NotificationType = this.decodeNotificationTypes(notification.data.type);

    switch (type) {
      case NotificationType.message:
        return 'Messages';
      case NotificationType.caseStatus:
        return 'Home';
      case NotificationType.painLevel:
        return 'Pain Level';
      case NotificationType.treatment:
        return 'Treatments';
      case NotificationType.treatmentScheduled:
        return 'Treatment Scheduled';
      case NotificationType.onboardingReminder:
        return 'Client Case Selection';
      case NotificationType.callRescheduled:
        return 'Call Rescheduled';
      case NotificationType.tasks:
          return 'Tasks';
      default:
        return null;
    }
  }

}
