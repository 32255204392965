<div class="contact-how ion-page">
  <ion-header mode="ios">
    <ion-toolbar mode="ios" class="header-toolbar">
      <ion-title><div class="ion-text-wrap">{{ 'CALL_LEGAL_TEAM.MEETING_DETAILS.MEETING_INFO' | translate }}</div></ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="closeMeetingModal()" routerLink="/tabs/case" routerDirection="back">
          <ion-icon src="assets/icon/close-button.svg"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content fullscreen="true">
    <div class="art-row">
      <ng-lottie width="327px" height="290px" [options]="options"></ng-lottie>
    </div>
    <ion-grid>
      <ion-row class="header-row">
        <ion-col size="12">
          <ion-text class="header-text-1">{{ call.case_team_name }}</ion-text>
          <ion-text class="header-text-1">
            {{ call.rescheduled_date ? formatMeetingDateTime(call.rescheduled_date) : formatMeetingDateTime(call.appointment_date) }}
          </ion-text>
          <ion-text class="header-text-2">{{ call.user_notes }}</ion-text>
          <ion-text class="header-text-3" [ngSwitch]="call.topic">
            <ng-container *ngSwitchCase="'Case Update'">
              {{ 'CALL_LEGAL_TEAM.HELP.CASE_UPDATE' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="'Health'">
              {{ 'CALL_LEGAL_TEAM.HELP.HEALTH' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="'Insurance'">
              {{ 'CALL_LEGAL_TEAM.HELP.INSURANCE' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="'Medical Bills'">
              {{ 'CALL_LEGAL_TEAM.HELP.MEDICAL_BILLS' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="'Property Damage'">
              {{ 'CALL_LEGAL_TEAM.HELP.PROPERTY_DAMAGE' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="'Something Else'">
              {{ 'CALL_LEGAL_TEAM.HELP.SOMETHING_ELSE' | translate }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ call.topic }}
            </ng-container>
          </ion-text>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</div>
