import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, map, tap, combineLatest, shareReplay, of } from 'rxjs';
import { App } from '@capacitor/app';
import { AppInfo } from '@capacitor/app/dist/esm/definitions';
import { Capacitor } from '@capacitor/core';
import * as semver from 'semver';
export interface UpgradeCheckResponse {
  ios: Platform;
  android: Platform;
  outageEnabled: boolean;
}
export interface Platform{
  latest: string;
  minimum: string;
  url: string;
  enabled: boolean;
  content: {
    heading: string;
    body: string;
    button: string;
  }
}
export interface UpgradeRequired{
  required: boolean;
  url: string;
  heading: string;
  body: string;
  button: string;
  outageEnabled: boolean;
}
@Injectable({
  providedIn: 'root'
})

export class UpgradeService {

  constructor(private http: HttpClient) {}

  protected staticUrl = 'https://s3.us-west-2.amazonaws.com/static.forthepeople.com/client-portal-mobile/app-version-check.json';

  protected isIos: boolean = Capacitor.getPlatform() === 'ios';
  
  public upgradeRequired$: Observable<UpgradeRequired> = 
    combineLatest([
      this.http.get<UpgradeCheckResponse>(this.staticUrl),
      Capacitor.getPlatform()!=='web' ? from(App.getInfo()) : of({version: '0.0.0'}),
    ])
    .pipe(
      map( ([upgradeCheckResponse, appInfo]: [UpgradeCheckResponse, AppInfo]) => {
        const platform: Platform = this.isIos ? upgradeCheckResponse.ios : upgradeCheckResponse.android;
        return [upgradeCheckResponse, platform, appInfo];
      }),
      map( ([upgradeCheckResponse, platform, appInfo]: [UpgradeCheckResponse, Platform, AppInfo]) => ({
        required: semver.lt(appInfo.version, platform.minimum),
        url: platform.url,
        heading: platform.content.heading,
        body: platform.content.body,
        button: platform.content.button,
        outageEnabled: upgradeCheckResponse.outageEnabled,
      })),
      shareReplay(1),
      tap( (upgradeRequired: UpgradeRequired) => console.log('upgradeService: upgradeRequired: ', upgradeRequired))
    )
}
