import { Injectable } from '@angular/core';
import { addDays, differenceInMinutes, set } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

@Injectable({
  providedIn: 'root',
})
export class PainLevelReminderUtil {
  constructor() {}

  public toEasternTimeZone = (date: Date) => zonedTimeToUtc(date, 'America/New_York');

  public atNoon = (date: Date) => set(date, { hours: 12, minutes: 0, seconds: 0 });

  /*
  Possible weekday values:Sunday = 0,Monday = 1,Tuesday = 2,Wednesday = 3,Thursday = 4,Friday = 5,Saturday = 6
  *
  * */
  public nextOccurringDay = (dow) => {
    const d = set(new Date(), { hours: 12, minutes: 0, seconds: 0 });
    d.setDate(d.getDate() + (dow + (7 - d.getDay())) % 7);
    const diffInMinutes = differenceInMinutes(d, new Date());

    return diffInMinutes <= 0 ? addDays(d, 7) : d;
  };
}
