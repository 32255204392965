import { environment as env, auth0WebOptions as webOptions, extraSettings } from './environment.prod';

export const auth0WebOptions = {
  ...webOptions,
  redirectUri: 'https://app.forthepeople.com/login',
  logoutUrl: 'https://app.forthepeople.com/logout',
};

export const environment = {
  ...env,
  auth0: {
    ...env.auth0,
    web: {
      ...auth0WebOptions,
      extraSettings: extraSettings
    },
  },
  webRender: true,
  monitoring: {
    ...env.monitoring,
    datadog: {
      ...env.monitoring.datadog,
      applicationId: '7c58d7f6-759a-4d52-a37e-3cec6be56005',
      clientToken: 'pub1b0973db7edd56d8b8ab18e03fdfc994',
      service: 'mmclientmobile-web',
      env: 'prod'
    }
  },
};

// console.log("WEBOPTIONS - environment.admin-render.ts", auth0WebOptions);