import { Injectable, OnDestroy } from '@angular/core';
import { Memoize } from 'typescript-memoize';
import { BehaviorSubject, map, Observable, of, shareReplay, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { indicate } from 'src/app/services/util/rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ClientCase } from 'src/app/services/case.service';
@Injectable({
  providedIn: 'root',
})
export class AppSchemaService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  private templateLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public templateLoading$ = this.templateLoadingSubject$.asObservable();

  private templateUpdate$ = new BehaviorSubject<AppSchema>(null);

  public getTemplate(clientCase: ClientCase): Observable<AppSchema> {
    return of(clientCase).pipe(
      takeUntil(this.unsubscribe$),
      map((caseData: ClientCase) => ({
        caseType: caseData.caseType,
        state: caseData.state,
        case_number: caseData.matter?.referenceNumber,
        intake_id: caseData.intakeId
      })),
      switchMap(({ caseType, state, case_number, intake_id }: { caseType: string, state: string, case_number: string, intake_id: string }) => this.getAppSchemaHTTP(caseType, state, intake_id)
        .pipe(
          indicate(this.templateLoadingSubject$),
          map((schema: AppSchemaResponse) => ({ ...schema, case_number })),
        )
      ),
      shareReplay(1),
    );
  }

  constructor(private http: HttpClient) { }

  ngOnDestroy(): void {
    this.templateUpdate$.next(null);
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  @Memoize((caseType: string, state: string, intake_id: string) => {
    return `${caseType};${state};${intake_id}`;
  })
  public getAppSchemaHTTP(caseType: string, state: string, intake_id: string): Observable<AppSchema> {
    return this.http.get<AppSchema>(`${environment.api.edwinBaseUrl}/app_case_schemas/?case_type=${caseType}&location_state=${state}&intake_id=${intake_id}`)
      .pipe(
        shareReplay(1),
      );
  }
}

export interface SurveyTemplateInfo {
  survey_name: string;
}

export interface CaseStatus {
  status: string;
  display_name: string;
}

export interface AppSchema extends AppSchemaResponse {
  case_number: string;
}
export interface AppSchemaResponse {
  id: string;
  case_type: string;
  version: number;
  state: string;
  content: {
    available_features: AppFeatures;
    survey_template_name: string;
    available_case_statuses: CaseStatus[];
    PDQ_survey_templates: SurveyTemplateInfo[];
  };
}

export interface AppFeatures {
  call_scheduling: boolean;
  onboarding_surveys: boolean;
  legal_team_section: boolean;
  principal_attorney_1: boolean;
  principal_attorney_2: boolean;
  messaging: boolean;
  pain_level: boolean;
  docs: boolean;
  PDQs: boolean;
  FAQs: boolean;
  case_status: boolean;
  health_tab: boolean;
  initial_treatment: boolean;
}
